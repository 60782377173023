/**
 * Different means to withdraw funds.
 */
export enum WithdrawalType {
    PAYPAL = "PAYPAL",
    MONERO = "MONERO",
    ROBUX = "ROBUX"
}

/**
 * The minimum amount to withdraw each withdrawal type.
 */
export const WithdrawalTypeMinimum: Record<WithdrawalType, number> = {
    PAYPAL: 10,
    ROBUX: 5,
    MONERO: 1
}