import { atom } from "jotai"

/**
 * The site's SEO description
 */
export const SeoDescription = atom("")

/**
 * The site's SEO title
 */
export const SeoTitle = atom("")

/**
 * If the mobile search bar is enabled.
 */
export const MobileSearchEnabled = atom(false)