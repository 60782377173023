import React from "react"

/**
 * Props for the input element.
 */
type InputProps = {
    type?: string // checkbox implements special styles
    error?: string // error message below input
    errorSizing?: string // sizing of error message (def: max-w-xs_
    tooltip?: string // slightly greyed message below input
    onEnter?: () => void // event when pressing enter
    iconLeft?: JSX.Element // an icon shown to the right
    iconRight?: JSX.Element // an icon shown to the right
} & React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
>

/**
 * An input wrapper with custom styles.
 */
const Input: React.FC<InputProps> = (props) => {
    const { className, type, onEnter, error, tooltip, iconLeft, iconRight } =
        props

    let inputClass
    switch (type) {
        case "checkbox":
            inputClass = "checkBoxInput"
            break
        default:
            inputClass = "textInput"
            break
    }

    return (
        <div>
            <div className="flex flex-row secondaryBackground rounded-lg justify-between">
                <div className="flex flex-row w-full">
                    {iconLeft && (
                        <div className="flex flex-col justify-center pl-4 items-center">
                            {iconLeft}
                        </div>
                    )}
                    <input
                        {...props}
                        onKeyDown={(e) => {
                            if (e.key === "Enter" && onEnter) {
                                onEnter()
                            }
                        }}
                        className={`${
                            className ? className : ""
                        } ${inputClass}`}
                    />
                </div>

                {iconRight && (
                    <div className="flex flex-col justify-center pr-4 items-center">
                        {iconRight}
                    </div>
                )}
            </div>

            {tooltip && (!error || error === "") && (
                <span className="text-xs ml-1 text-greyText dark:text-dark_greyText">
                    {tooltip}
                </span>
            )}

            {error && (
                <span className={`${props.errorSizing ? props.errorSizing : "max-w-xs"} text-center text-ellipsis block text-xs ml-1 text-danger`}>
                    {error}
                </span>
            )}
        </div>
    )
}

export default Input
