import {atom} from "jotai"
import {atomWithStorage} from "jotai/utils"
import {AccountStore} from "@features/users/account/api/models/AccountStore"

/**
 * The account atom.
 * This is initially set through a useEffect in index.tsx.
 * Account details are loaded through the API.
 */
export const account = atom({} as AccountStore | undefined)

/**
 * Get the user's balance.
 */
export const accountBalance = atom(
    (get) => get(account)?.payment?.balance ?? -1
)

/**
 * The account session.
 */
const sessionToken = atomWithStorage("session", "")

/**
 * Authorization token
 */
const token = atomWithStorage("token", "")

export const setToken = atom(null, (get, set, update: string) => {
    // `update` is any single value we receive for updating this atom
    set(token, update)
})

export const signOut = atom(null, (get, set) => {
    set(token, "")
})

/**
 * Get the session token.
 */
export const getToken = atom((get) => get(token))

/**
 * If the user is logged in.
 * Calculated by seeing if [sessionToken] has any contents.
 */
export const isLoggedIn = atom((get) => {
    const ses = get(token)

    return ses && ses.length > 0
})

/**
 * If the MFA toggle modal is visible
 */
export const mfaToggleVisible = atom(false)

/**
 * When mfaToggleVisible is set to true, this is what new expected
 * MFA status will be.
 *
 * For example, if it's set to visible and this is true, the modal will
 * assume the user's attempting to enable MFA.
 */
export const mfaToggleStatus = atom(false)

/**
 * If the ad options modal is visible
 */
export const adOptionsModal = atom(false)