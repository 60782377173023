import { atom, useAtom } from "jotai"
import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import Spinner from "@components/Spinner"
import { loginModalVisibility } from "@features/users/login/Login.atom"
import {
    account,
    accountBalance,
    isLoggedIn,
    signOut,
} from "@features/users/account/Account.atom"
import { feedbackModalVisibility } from "@features/feedback/components/FeedbackModal"
import { CSSTransition } from "react-transition-group"
import { Transition } from "@headlessui/react"

export const userDropDownVisibility = atom(false)

const UserDropDown = () => {
    const nav = useNavigate()

    // Used to find the dropdown's visiblity state
    const [visible, setVisible] = useAtom(userDropDownVisibility)

    // if the feedback modal is visible
    const [, setFeedbackVisible] = useAtom(feedbackModalVisibility)

    // this is used to check if userDropDownVisibliy is proper
    // this prevents slight seconds of visibility when there shouldn't be
    const [localVisibility, setLocalVisibility] = useState(false)

    // Used to enable the dropdown login
    const [, setLoginVisible] = useAtom(loginModalVisibility)

    const [balance] = useAtom(accountBalance)

    // If the user's logged in. Used to calculate whether to show the dropdown or a login screen.
    const [loggedIn] = useAtom(isLoggedIn)

    const [, logOut] = useAtom(signOut)

    // Account data
    const [acc] = useAtom(account)

    // manages the difference between visible and local visibility
    // basically prevents it from going visible when it really doesn't need to be
    useEffect(() => {
        // if the user isn't logged in, offer them the login modal
        if (visible && !loggedIn) {
            setLoginVisible(true)
            setVisible(false)
            return
        }

        setLocalVisibility(visible)
    }, [visible, setLoginVisible, loggedIn, setVisible])

    // set the account token to nothing, and refresh
    // this logs the user out basically
    const signOutButton = () => {
        logOut()
        window.location.reload()
    }

    const dropDownRef = React.useRef<HTMLDivElement>(null)
    useEffect(() => {
        const handleClick = (event: MouseEvent) => {
            if (
                dropDownRef.current &&
                !dropDownRef.current.contains(event.target as Node) &&
                !document
                    .getElementById("account-button")
                    ?.contains(event.target as Node)
            ) {
                setVisible(false)
            }
        }

        document.addEventListener("click", handleClick)

        return () => {
            document.removeEventListener("click", handleClick)
        }
    }, [dropDownRef, setVisible])

    return (
        <Transition
            className="z-50"
            enter="transition-all ease-in-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-all ease-in-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            show={localVisibility}
        >
            <div
                onBlur={() => setVisible(false)}
                ref={dropDownRef}
                className="transition-all userDropDown"
            >
                {/* Username, offers profile */}
                <div className="text-center text-gray-900 dark:text-white">
                    <Link
                        className="text-center text-lg py-3 px-4"
                        to={`/@${acc?.username}`}
                        onClick={() => setVisible(false)}
                    >
                        {acc?.username}
                    </Link>

                    <p className="mb-2">
                        {balance !== -1 ? (
                            `$${balance.toFixed(2)}`
                        ) : (
                            <Spinner />
                        )}
                    </p>
                </div>

                {/* Dropdown navigation options */}
                <ul
                    className="py-1 text-sm text-gray-700 dark:text-stone-300"
                    aria-labelledby="dropdownInformationButton"
                >
                    <li>
                        <Link
                            className="entry"
                            to={"/account/dashboard"}
                            onClick={() => setVisible(false)}
                        >
                            <i className="fa-solid fa-gauge"></i> Dashboard
                        </Link>
                    </li>

                    <li>
                        <button
                            className="entry w-full"
                            onClick={() => {
                                setVisible(false)
                                setFeedbackVisible(true)
                            }}
                        >
                            <i className="fa-solid fa-person"></i> Feedback
                        </button>
                    </li>

                    <li>
                        <button
                            className="entry w-full"
                            onClick={() => {
                                setVisible(false)
                                nav("/support")
                            }}
                        >
                            <i className="fa-solid fa-life-ring"></i> Support
                        </button>
                    </li>
                </ul>

                {/* Sign out option */}
                <div className="pt-2 text-center">
                    <p
                        className="entry text-sm"
                        onClick={() => signOutButton()}
                    >
                        <i className="fa-solid fa-right-from-bracket"></i>
                        &nbsp;&nbsp;Sign out
                    </p>
                </div>
            </div>
        </Transition>
    )
}

export default UserDropDown
