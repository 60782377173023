import { useAtom } from "jotai"
import { PasteKeyAnalytics } from "@features/keys/Keys.atom"
import GetPasteAnalytics from "@features/keys/api/models/responses/GetPasteAnalytics"
import { useEffect } from "react"
import { getPasteKeyAnalytics } from "@features/keys/api/ManagePasteKeys"
import { getToken } from "@features/users/account/Account.atom"
import toast from "react-hot-toast"

/**
 * Automatically retrieve the PasteKeyAnalytics, if they're not already loaded, and return them.
 */
const usePasteKeyAnalytics = (useLoading: boolean = false): [GetPasteAnalytics | undefined] => {
    const [session] = useAtom(getToken)
    const [analytics, setAnalytics] = useAtom(PasteKeyAnalytics)

    useEffect(() => {
        const loadAnalytics = async () => {
            try {
                const analyticsRequest = await getPasteKeyAnalytics(session, 1)

                setAnalytics(analyticsRequest)
            } catch (err) {
                toast.error(err as string)
            }
        }

        if (!analytics && useLoading) {
            loadAnalytics().finally(() => console.log("Loaded key analytics!"))
        }
    }, [analytics, session, setAnalytics])

    return [analytics]
}

export default usePasteKeyAnalytics
