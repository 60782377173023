import PerPasteRevenue from "@features/dashboard/components/statistics/api/models/PerPasteRevenue";
import {API} from "@util/Api";
import Payload from "@api/Payload";

/**
 * Get per paste revenue values for a day.
 *
 * @param sessionToken The session token.
 * @param date The date to get per paste revenue values for.
 */
export const getPerPasteRevenue = async (sessionToken: string, date: number): Promise<PerPasteRevenue> => {
    const request = await API.get(`/user/stats?date=${date}`, {
        headers: {
            "Authorization": `Bearer ${sessionToken}`
        }
    })

    const body: Payload<PerPasteRevenue> = request.data

    if (request.status === 200 && body.payload) {
        return body.payload
    } else {
        return Promise.reject(body.message)
    }
}