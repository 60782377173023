import {
    GetStatistics,
    RevenueReport,
} from "@features/users/account/api/models/responses/GetStatistics"
import { BASE_URL } from "@util/Util"
import Payload from "@api/Payload"
import PaginatedResponse, { PaginatedMapResponse } from "@api/PaginatedResponse"
import { API, header } from "@util/Api"
import { Withdrawal } from "@features/users/account/api/models/Payout"

/**
 * Retrieve the user's view counts.
 * @param session The session token.
 */
export const getStatistics = async (
    session: string
): Promise<GetStatistics> => {
    const request = await API.get("/user/stats",  header(session))

    const body: Payload<GetStatistics> = request.data

    return request.status === 200 && body.payload
        ? body.payload
        : Promise.reject(body.message)
}

/**
 * Retrieve a user's revenue range.
 *
 * @param session The session token.
 * @param range The range to find the revenue for.
 */
export const getRevenueRange = async (
    session: string,
    range: string
): Promise<RevenueReport> => {
    const request = await API.get(
        `${BASE_URL}/user/stats?range=${range}`,
        header(session)
    )
    const { payload, message }: Payload<RevenueReport> = request.data

    return request.status === 200 && payload ? payload : Promise.reject(message)
}

/**
 * Load a page of revenue history.
 *
 * @param session The session token.
 * @param page The page of revenue history.
 */
export const getRevenueHistory = async (
    session: string,
    page: number
): Promise<PaginatedMapResponse<number, number>> => {
    const request = await API.get(`/user/stats?page=${page}`, header(session))
    const { payload, message }: Payload<PaginatedMapResponse<number, number>> =
        request.data

    return request.status === 200 && payload ? payload : Promise.reject(message)
}

/**
 * Get a user's payout history.
 *
 * @param session The session token.
 * @param page the page number
 */
export const getPayoutHistory = async (
    session: string,
    page: number
): Promise<PaginatedResponse<Withdrawal>> => {
    const request = await API.get(
        `/user/bal/history?page=${page}`,
        header(session)
    )
    const { payload, message }: Payload<PaginatedResponse<Withdrawal>> =
        request.data

    return request.status === 200 && payload ? payload : Promise.reject(message)
}
