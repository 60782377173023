import { useNavigate } from "react-router-dom"

/**
 * The error 404 page.
 */
const NotFound = () => {
    const nav = useNavigate()

    return (
        <div className="h-80 flex items-center flex-col justify-center">
            <h2>That page could not be found!</h2>
            <button
                onClick={() => nav("/")}
                className="text-blue-600 hover:text-blue-800"
            >
                go home
            </button>
        </div>
    )
}

export default NotFound
