import { capitalize } from "./Util"
import React, { useEffect, useState } from "react"
import { CSSTransition } from "react-transition-group"
import { Transition } from "@headlessui/react"

/**
 * A button with a different timeranges.
 *
 * @param id The custom ID of this range.
 * @param defaultRange
 * @param ranges
 */
const useRange = (
    id: string,
    defaultRange: string = "daily",
    ranges: Record<string, string> = {
        daily: "Daily",
        weekly: "Weekly",
        lifetime: "Lifetime",
    }
): [string, JSX.Element] => {
    const [visible, setVisible] = useState(false)
    const dropDownRef = React.createRef<HTMLUListElement>()
    const [range, setRange] = useState(defaultRange)

    // closes the dropdown when the user clicks off
    useEffect(() => {
        const handleClick = (event: MouseEvent) => {
            if (
                dropDownRef.current &&
                !dropDownRef.current.contains(event.target as Node) &&
                !document
                    .getElementById("range-button" + id)
                    ?.contains(event.target as Node)
            ) {
                setVisible(false)
            }
        }

        document.addEventListener("click", handleClick)

        return () => {
            document.removeEventListener("click", handleClick)
        }
    }, [id, dropDownRef])

    useEffect(() => {
        setVisible(false)
    }, [range])

    return [
        range,
        <div className="flex flex-row justify-center z-10">
            <div>
                <div
                    className={`select-none textInput gap-1 flex flex-row justify-between items-center cursor-pointer caret-transparent`}
                    onClick={() => setVisible(!visible)}
                    id={`range-button${id}`}
                >
                    <span>{capitalize(ranges[range])}</span>
                    <span>
                        {visible ? (
                            <i className="fa-solid fa-caret-up"></i>
                        ) : (
                            <i className="fa-solid fa-caret-down"></i>
                        )}
                    </span>
                </div>

                <Transition
                    enter="transition-all duration-200"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-all duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    show={visible}
                    ref={dropDownRef}
                >
                    {/* Different period options */}
                    <ul
                        className={`dropdown transition-all`}
                        style={{
                            maxWidth: "5rem",
                        }}
                    >
                        {Object.keys(ranges).map((range) => (
                            <li>
                                <button
                                    onClick={() => setRange(range)}
                                    type="button"
                                >
                                    {ranges[range]}
                                </button>
                            </li>
                        ))}
                    </ul>
                </Transition>
            </div>
        </div>,
    ]
}

/**
 * The messages for the buttons.
 */
interface RangeMessages {
    daily: string
    weekly: string
    lifetime: string
}

export default useRange
