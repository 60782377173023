import { useAtom } from "jotai"
import {
    withdrawalProcess,
    withdrawalType,
} from "@features/users/withdrawals/Withdrawals.atom"
import Modal from "@components/Modal"
import React, { useState } from "react"
import Button from "@components/inputs/Button"
import { WithdrawalType } from "@features/users/withdrawals/api/models/WithdrawalType"
import { Link } from "react-router-dom"
import { account } from "@features/users/account/Account.atom"
import toast from "react-hot-toast"

/**
 * A modal to select which type of withdrawal the user wants.
 */
const WithdrawalTypeSelection = () => {
    const [step, setStep] = useAtom(withdrawalProcess)

    const [type, setType] = useAtom(withdrawalType)
    const [acc] = useAtom(account)

    return (
        <Modal
            visible={step === 1}
            setVisible={() => setStep(0)}
            title={"Withdrawal Type"}
        >
            <div className="flex flex-row justify-evenly items-start flex-wrap gap-4 my-10">
                {/* Monero Payout Option*/}
                <Button
                    buttonStyle="primary"
                    className="flex flex-col items-center justify-center w-[164px]"
                    onClick={() => {
                        setStep(2)
                        setType(WithdrawalType.MONERO)
                    }}
                >
                    <i className="fab fa-monero fa-8x" /> Monero
                </Button>

                {/* PayPal Payout Option*/}
                <div className="flex flex-col justify-center items-center">
                    <Button
                        buttonStyle="primary"
                        className="flex flex-col items-center justify-center w-[164px]"
                        onClick={() => {
                            if (10 > (acc?.payment?.balance ?? 0)) {
                                toast.error("You need at least $10 to withdraw through PayPal!")
                            } else {
                                setStep(2)
                                setType(WithdrawalType.PAYPAL)
                            }
                        }}
                    >
                        <i className="fab fa-paypal fa-8x" /> PayPal
                    </Button>

                    {/* PayPal fee */}
                    <p>5% fee</p>
                </div>

                {/* Robux Payout Option*/}
                <div className="flex flex-col justify-center items-center">
                    <Button
                        buttonStyle="primary"
                        className="flex flex-col items-center justify-center w-[164px]"
                        onClick={() => {
                            if (5 > (acc?.payment?.balance ?? 0)) {
                                toast.error("You need at least $5 to withdraw through Robux!")
                            } else {
                                setStep(2)
                                setType(WithdrawalType.ROBUX)
                            }
                        }}
                    >
                        <img src="/img/robux.svg" width={102.98}  alt="Robux Logo"/> Robux
                    </Button>
                </div>
            </div>

            <div className="m-6 flex items-center justify-center">
                    <Link className="w-full text-blue-500 underline text-center" to="/crypto">
                        Learn about Monero and it's advantages.
                    </Link>
            </div>

            <div className="flex flex-row justify-between">
                <Button buttonStyle="secondary" onClick={() => setStep(0)}>
                    <i className="fa-solid fa-arrow-left" /> Exit
                </Button>
            </div>
        </Modal>
    )
}

export default WithdrawalTypeSelection
