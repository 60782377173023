import Input from "@components/inputs/Input"
import { useEffect, useState } from "react"
import TextArea from "@components/inputs/TextArea"
import Button from "@components/inputs/Button"
import { createSupport } from "@features/feedback/api/Support"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import { useAtom } from "jotai"
import { account, isLoggedIn } from "@features/users/account/Account.atom"

/**
 * Manages support requests.
 */
const Support = () => {
    const nav = useNavigate()

    const [username, setUsername] = useState("")
    const [email, setEmail] = useState("")
    const [details, setDetails] = useState("")

    const [loading, setLoading] = useState(false)

    const [acc] = useAtom(account)
    const [loggedIn] = useAtom(isLoggedIn)

    useEffect(() => {
        setUsername(acc?.username ?? "")
        setEmail(acc?.email ?? "")
    }, [acc])

    const onSubmit = async () => {
        setLoading(true)

        if (username === "" || email !== "" || details === "") {
            toast.error("Please make sure all values are filled out.")

            setLoading(false)
            return
        }

        const requestSuccessful = await createSupport(username, email, details)

        if (requestSuccessful) {
            setUsername("")
            setEmail("")
            setDetails("")

            toast.success(
                "Your support request has been successfully created! Please allow up to 3 days for a response."
            )

            nav("/")
        } else {
            toast.error(
                "There was an issue with your request, please try again."
            )
        }

        setLoading(false)
    }

    return (
        <div className="flex flex-row items-center justify-center">
            <div className="island rounded-lg m-4 p-8 max-w-xl flex flex-col gap-4">
                <h2 className="text-2xl poppins">Support</h2>

                <div className="flex flex-col md:flex-row gap-2">
                    <Input
                        value={username}
                        disabled={loggedIn === true}
                        onChange={(e) => setUsername(e.currentTarget.value)}
                        placeholder="Username"
                    />

                    <Input
                        value={email}
                        disabled={loggedIn === true && acc?.email !== undefined}
                        onChange={(e) => setEmail(e.currentTarget.value)}
                        placeholder="Email"
                    />
                </div>

                <TextArea
                    value={details}
                    onChange={(e) => setDetails(e.currentTarget.value)}
                    placeholder="Support details"
                    className="min-h-[125px]"
                />

                <Button className="pt-2" loading={loading} onClick={onSubmit}>
                    Send Request
                </Button>
            </div>
        </div>
    )
}

export default Support
