import { BASE_URL } from "@util/Util"
import { API, body, header } from "@util/Api"
import Payload from "@api/Payload"

/**
 * Create a feedback.
 *
 * @param sesToken The authorized session token.
 * @param content The content of the feedback. 3 > content len > 1000
 * @param category The category of the feedback
 * @returns An object, returning the success and message from the backend.
 */
export const createFeedback = async (
    sesToken: string,
    content: string,
    category: string
): Promise<string> => {
    const formData = new FormData()

    formData.append("content", content)
    formData.append("category", category)

    const request = await API.post(
        `${BASE_URL}/feedback`,
        body({ content, category }),
        header(sesToken, true)
    )

    const { payload, message }: Payload<{ feedbackID: string }> = request.data

    return request.status === 200 && payload
        ? payload.feedbackID
        : Promise.reject(message)
}
