import { atom } from "jotai"
import { WithdrawalType } from "@features/users/withdrawals/api/models/WithdrawalType"

/**
 * What step through the withdrawal process the user is.
 *
 * 0 = Nothing visible.
 * 1 = Selection between withdrawal types.
 * 2 = Modification of withdrawal details (ex = XMR address)
 * 3 = Confirmation
 */
export const withdrawalProcess = atom(0)

/**
 * The amount the user's withdrawing.
 */
export const withdrawalAmount = atom(0)

/**
 * The type of withdrawal the user selected.
 */
export const withdrawalType = atom<WithdrawalType>(WithdrawalType.PAYPAL)

/**
 * The Roblox game passes' universe ID.
 */
export const robloxUniverseId = atom<number>(0)

/**
 * The current amount of Robux that BloxyBin has.
 */
export const robuxSupply = atom<number>(0)
