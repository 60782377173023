import { useAtom } from "jotai"
import {
    withdrawalAmount,
    withdrawalProcess,
    withdrawalType,
} from "@features/users/withdrawals/Withdrawals.atom"
import Button from "@components/inputs/Button"
import Modal from "@components/Modal"
import React, { useState } from "react"
import { account, getToken } from "@features/users/account/Account.atom"
import { withdraw } from "@features/users/withdrawals/api/Withdrawals"
import toast from "react-hot-toast"
import { WithdrawalType } from "@features/users/withdrawals/api/models/WithdrawalType"

function formatXmrAddress(address: string) {
    return `${address.substring(0, 5)}...${address.substring(
        address.length - 5,
        address.length
    )}`
}

/**
 * Overview details of the withdrawal.
 */
const WithdrawalOverview = () => {
    const PAYPAL_FEE = 0.05

    const [amount] = useAtom(withdrawalAmount)

    const [step, setStep] = useAtom(withdrawalProcess)
    const [type] = useAtom(withdrawalType)
    const [acc, setAcc] = useAtom(account)

    const paymentAddress =
        type === "PAYPAL"
            ? acc?.payment?.paypalAddress
            : formatXmrAddress(acc?.payment?.paymentAddress ?? "")

    const [session] = useAtom(getToken)

    const [loading, setLoading] = useState(false)

    const balance = Number(Math.round(parseFloat(`${amount}e2`)) + "e-2")
    let balanceWithFees =
        type === "MONERO" ? balance : balance - balance * PAYPAL_FEE

    const createWithdrawal = async () => {
        setLoading(true)

        try {
            const withdrawRequest = await withdraw(session, type, balance)

            setAcc((prev) => {
                prev!!.payment.balance = prev!!.payment.balance - balanceWithFees

                return prev
            })

            toast.success(withdrawRequest)
            setStep(0)
        } catch (e) {
            toast.error(`${e}`)
        }

        setLoading(false)
    }

    return (
        <Modal
            visible={step === 4 && type !== WithdrawalType.ROBUX}
            setVisible={() => setStep(0)}
            title={"Withdrawal Overview"}
            seriesTransitioning={true}
        >
            <div className="m-6">
                <div className="flex flex-row justify-between">
                    <p>Type</p>
                    <p className="monospace">{type}</p>
                </div>

                <div className="flex flex-row justify-between">
                    <p>Receiving Address</p>
                    <p className="monospace break-all max-w-[200px]">
                        {paymentAddress}
                    </p>
                </div>

                <div className="flex flex-row justify-between mt-4">
                    <p>Amount</p>
                    <p className="monospace">${balance.toFixed(2)}</p>
                </div>

                {/* Fees only apply to PayPal */}
                {type === "PAYPAL" && (
                    <div className="flex flex-row justify-between">
                        <p>Fees (5%)</p>
                        <p className="monospace">
                            - ${(balance * PAYPAL_FEE).toFixed(2)}
                        </p>
                    </div>
                )}

                <div className="flex flex-row justify-between">
                    <p>Total</p>
                    <p className="monospace">${balanceWithFees.toFixed(2)}</p>
                </div>

                <div className="flex flex-row justify-between mt-4">
                    <p>Remaining Balance</p>
                    <p className="monospace">
                        $
                        {(
                            (acc?.payment?.balance ?? 0) - balance
                        ).toFixed(2)}
                    </p>
                </div>
            </div>

            <div className="flex flex-row justify-between">
                <Button buttonStyle="secondary" onClick={() => setStep(3)}>
                    <i className="fa-solid fa-arrow-left" /> Back
                </Button>

                <Button
                    buttonStyle="primary"
                    loading={loading}
                    onClick={createWithdrawal}
                >
                    <i className="fa-solid fa-check" /> Confirm Withdrawal
                </Button>
            </div>
        </Modal>
    )
}

export default WithdrawalOverview
