import { useAtom } from "jotai"
import React, { useEffect, useState } from "react"
import toast from "react-hot-toast"
import Modal from "@components/Modal"
import Input from "@components/inputs/Input"
import Button from "@components/inputs/Button"
import { account, getToken } from "@features/users/account/Account.atom"
import { updateAccountInformation } from "@features/users/account/api/UpdateAccount"
import { AccountStore } from "@features/users/account/api/models/AccountStore"
import TextArea from "@components/inputs/TextArea"

/**
 * A modal used to update account properties seen on the account page.
 */
export const usePropUpdateModal = (): [
    JSX.Element,
    (
        propName: string,
        apiName: string,
        isSecret: boolean,
        currentValue?: string
    ) => void
] => {
    const [visible, setVisible] = useState(false)

    // used to update after a successful request.
    const [, setAcc] = useAtom(account)

    const [propName, setPropName] = useState("") // the name of the property being updated.
    const [apiName, setApiName] = useState("") // the name of the property on the backend.
    const [isSecret, setIsSecret] = useState(false) // used for the password for no plaintext.
    const [currentValue, setCurrentValue] = useState<string>() // the initial value

    // holds if the button should be loading
    const [loading, setLoading] = useState(false)

    // used for updating the account information.
    const [sesToken] = useAtom(getToken)

    // the ref for the input.
    const propRef = React.useRef(null as null | HTMLFormElement)

    // This wraps the updateAccountInformation function
    const updateProperty = async (
        keyName: string,
        newValue: string
    ): Promise<void> => {
        return await updateAccountInformation(sesToken, keyName, newValue)
    }

    // When the save button is clicked.
    const onSubmit = async () => {
        const input = propRef.current as HTMLFormElement
        const value = Object.fromEntries(new FormData(input)).property as string

        setLoading(true)

        try {
            await updateProperty(apiName, value)

            // password is the only property not updated client side.
            if (apiName !== "password") {
                switch (apiName) {
                    case "discordLink":
                    case "youtubeLink": {
                        setAcc(
                            (prev) =>
                                ({
                                    ...prev,
                                    profile: {
                                        ...prev?.profile,
                                        [apiName]: value,
                                    },
                                } as AccountStore)
                        )
                        break
                    }

                    case "email": {
                        setAcc((prev) => {
                            return {
                                ...prev,
                                [apiName]: value,
                                emailVerified: false,
                            } as AccountStore
                        })
                        break
                    }

                    default: {
                        setAcc((prev) => {
                            return {
                                ...prev,
                                [apiName]: value,
                            } as AccountStore
                        })

                        break
                    }
                }
            }

            input.reset()

            setVisible(false)
            toast.success("Successfully updated!")
        } catch (e) {
            toast.error(`${e}`)
        }

        setLoading(false)
    }

    useEffect(() => {
        if (!visible) propRef.current?.reset()
    }, [visible])

    return [
        <Modal visible={visible} setVisible={setVisible} title="Update Account">
            <form className="space-y-6" ref={propRef}>
                <div>
                    <label
                        htmlFor="property"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                        {propName}

                        {currentValue && currentValue.length > 30 ? (
                            <TextArea
                                name="property"
                                id="property"
                                placeholder={`New ${propName.toLowerCase()}`}
                                defaultValue={currentValue}
                                required
                            />
                        ) : (
                            <Input
                                type={isSecret ? "password" : "text"}
                                name="property"
                                id="property"
                                placeholder={`New ${propName.toLowerCase()}`}
                                defaultValue={currentValue}
                                required
                            />
                        )}
                    </label>
                </div>

                <Button
                    type="submit"
                    disabled={loading}
                    loading={loading}
                    onClick={() => onSubmit()}
                >
                    Update {propName.toLowerCase()}
                </Button>
            </form>
        </Modal>,
        (
            propName: string,
            apiName: string,
            isSecret: boolean,
            currentValue?: string
        ) => {
            setVisible(true)
            setApiName(apiName)
            setIsSecret(isSecret)
            setPropName(propName)
            setCurrentValue(currentValue)
        },
    ]
}
