import { WithdrawalType } from "@features/users/withdrawals/api/models/WithdrawalType"
import { API, header } from "@util/Api"
import Payload from "@api/Payload"

/**
 * Create a request to withdraw.
 * @param session The session token.
 * @param type The type of withdrawal.
 * @param amount The amount of currency to withdraw.
 * @param universeId If the type is ROBUX, then a universe ID is required.
 */
export const withdraw = async (
    session: string,
    type: WithdrawalType,
    amount?: number,
    universeId?: number
): Promise<string> => {
    const body = new FormData()

    body.set("type", type)

    if (universeId) {
        body.set("universeId", `${universeId}`)
    }

    if (amount) {
        body.set("amount", `${amount}`)
    }

    const request = await API.post(
        "/user/withdraw",
        new URLSearchParams(body as any),
        {
            headers: {
                Authorization: `Bearer ${session}`,
            },
        }
    )

    const requestBody = request.data as Payload<undefined>

    if (request.status === 200) {
        return requestBody.message ?? ""
    } else {
        return Promise.reject(requestBody.message)
    }
}

/**
 * Validate a game pass.
 *
 * @param session User's auth token
 * @param url The Game pass url (full experience)
 * @param amount The amount of Robux the game pass should be for
 */
export const validateGamePass = async (
    session: string,
    url: string,
    amount: number
): Promise<number> => {
    const request = await API.get(
        `/user/withdrawal/robux?url=${url}&amount=${amount}`,
        header(session)
    )

    if (request.status !== 200) return Promise.reject(request.data.message)

    return request.data.payload.universeId
}
