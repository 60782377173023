import { useAtom } from "jotai"
import { useEffect } from "react"
import { themeAtom } from "@features/theme/Theme.atom"

/**
 * The theme selector seen on the header.
 */
const ThemeSelector = () => {
    const [theme, setTheme] = useAtom(themeAtom)

    // Toggle the theme between light and dark
    const toggleTheme = () => {
        if (theme === "light") setTheme("dark")
        else setTheme("light")
    }

    // Update the documentElement's classList depending on what the theme was changed to.
    useEffect(() => {
        let classList = document.documentElement.classList

        if (theme === "dark" && !classList.contains("dark")) {
            document.documentElement.classList.add("dark")
        } else if (theme === "light" && classList.contains("dark")) {
            document.documentElement.classList.remove("dark")
        }
    }, [theme])

    return (
        <button
            id="theme-toggle"
            className="nav-btn"
            onClick={() => toggleTheme()}
        >
            {/* If light is enabled */}
            <i className="text-lg fa-solid fa-sun dark:hidden block"/>

            {/* If dark is enabled */}
            <i className="text-lg fa-solid fa-moon dark:block hidden"/>
        </button>
    )
}

export default ThemeSelector
