import Modal from "@components/Modal"
import React, { useEffect, useState } from "react"
import { getRevenueHistory } from "@features/users/account/api/Statistics"
import { atom, useAtom } from "jotai"
import { getToken } from "@features/users/account/Account.atom"
import DayRevenue from "@features/dashboard/components/statistics/revenue/DayRevenue"
import Paginator from "@components/Paginator"

/**
 * If the per-day revenue modal is visible.
 *
 * @see PerDayRevenue
 * @see PasteRevenue
 */
export const perDayRevenueVisible = atom(false)

/**
 * Displays a modal with per-day revenue.
 *
 * @remark
 * To make this modal visible, use {@link perDayRevenueVisible}.
 *
 * @param range The range to display.
 */
const PerDayRevenue: React.FC<{ range: string }> = ({ range }) => {
    const [visible, setVisible] = useAtom(perDayRevenueVisible)
    const [ses] = useAtom(getToken)

    const [maxPage, setMaxPage] = useState(1)
    const [page, setPage] = useState(1)
    const [totalResults, setTotalResults] = useState(0)
    const [revenueHistory, setRevenueHistory] = useState({} as any)

    useEffect(() => {
        // loads a page of revenue history
        const loadRevenueHistoryPage = async (historyPage: number) => {
            await getRevenueHistory(ses, historyPage).then((history) => {
                setRevenueHistory((prev: any) => ({
                    ...prev,
                    [historyPage]: history.pageContents,
                }))
                setMaxPage(history.maxPage)
                setTotalResults(history.totalResults)
            })
        }

        if (!Object.hasOwn(revenueHistory, page) && visible) {
            loadRevenueHistoryPage(page).finally(() =>
                console.log(`Loaded revenue history page ${page}!`)
            )
        }
    }, [page, revenueHistory, ses, visible])

    return (
        <Modal
            visible={visible}
            setVisible={setVisible}
            title={"Revenue Information"}
        >
            <p className="-mt-2 mb-5">
                This only includes days that you have earned revenue.
            </p>

            <div className="overflow-hidden relative self-center mt-5">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase primaryBackground dark:text-gray-400">
                        <tr>
                            <th scope="col" className="py-3 px-6">
                                Date
                            </th>
                            <th scope="col" className="py-3 px-6">
                                Amount
                            </th>
                            <th scope="col" className="py-3 px-6">
                                Revenue Per Paste
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.hasOwn(revenueHistory, page) &&
                            Object.keys(revenueHistory[page]).map(
                                (key, index) => (
                                    <DayRevenue
                                        date={+key}
                                        index={index}
                                        amount={revenueHistory[page][key]}
                                    />
                                )
                            )}
                    </tbody>
                </table>

                <Paginator
                    currentPage={page}
                    setCurrentPage={setPage}
                    maxPage={maxPage}
                    totalResults={totalResults}
                    pageCount={10}
                />
            </div>
        </Modal>
    )
}

export default PerDayRevenue
