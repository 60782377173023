import { GetPasteResponse } from "@features/pastes/api/models/GetPasteResponse"
import { Link } from "react-router-dom"
import { timeAgo } from "@util/Util"
import React, { useEffect } from "react"

/**
 * Properties for {@link SearchModal}.
 *
 * @remark
 * This is split into it's own class due to the significant differences in searching between smaller and larger screens.
 * See their usages in {@link Search} and {@link MobileSearch}.
 *
 * @param visible If the modal is visible.
 * @param setVisible Set if the modal is visible, should change {@link visible}.
 * @param pastes The pastes returned from the search request.
 * @param clearSearch When the user clicks a paste or dismisses the modal, this clears the search and disables the modal.
 */
type SearchModalProps = {
    visible: boolean
    setVisible: (newVisibility: boolean) => void
    pastes: GetPasteResponse[]
    clearSearch: () => void
}

/**
 * The modal that displays search results.
 *
 * @param props See {@link SearchModalProps}
 *
 * @see MobileSearch
 * @see Search
 */
export default function SearchModal(props: SearchModalProps) {
    const searchRef = React.useRef<HTMLDivElement>(null)

    const { setVisible } = props
    useEffect(() => {
        const handleClick = (event: MouseEvent) => {
            if (
                searchRef.current &&
                !searchRef.current.contains(event.target as Node) &&
                !document
                    .getElementById("search-button")
                    ?.contains(event.target as Node)
            ) {
                setVisible(false)
            }
        }

        document.addEventListener("click", handleClick)

        return () => {
            document.removeEventListener("click", handleClick)
        }
    }, [searchRef, setVisible])

    return (
        <div
            hidden={!props.visible}
            ref={searchRef}
            className="absolute border dark:border-dark_border md:top-16 top-32 z-10 mt-2 md:w-1/2 w-full origin-top-right rounded-md primaryBackground shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
            tabIndex={-1}
        >
            <div
                className="p-2 flex flex-col divide-y divide dark:divide-dark_border dark:divide-opacity-30"
                role="none"
            >
                {props.pastes.length > 0 ? (
                    props.pastes.slice(0, 5).map((paste) => (
                        <div className=" flex flex-row justify-between p-2">
                            <Link
                                onClick={() => {
                                    props.clearSearch()
                                    setVisible(false)
                                }}
                                to={`/${paste.paste.pasteID}`}
                            >
                                {paste.paste.title
                                    ? paste.paste.title
                                    : "Untitled Paste"}
                            </Link>

                            <span className="monospace">
                                {timeAgo(new Date(paste.paste.date))}
                            </span>
                        </div>
                    ))
                ) : (
                    <span className="monospace text-center py-4">
                        There's no results for that query!
                    </span>
                )}
            </div>
        </div>
    )
}
