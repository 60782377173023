import { API } from "@util/Api"

/**
 * Create a support ticket.
 *
 * @param username Username of user requesting.
 * @param email Email of user requesting.
 * @param details Details of ticket.
 */
export const createSupport = async (username: string, email: string, details: string) => {
    const body = new FormData()

    body.set("username", username)
    body.set("email", email)
    body.set("details", details)

    const request = await API.post("/support", new URLSearchParams(body as any).toString())

    return request.status === 200
}