import React from "react"
import TimeRangeCard from "@components/TimeRangeCard"
import usePasteKeyAnalytics from "@features/keys/hooks/usePasteKeyAnalytics"

/**
 * Analaytics from the key system.
 */
const KeyRevenue = () => {
    const [analytics] = usePasteKeyAnalytics()

    const onUpdateRange = async (newRange: string): Promise<number> => {
        if (analytics) {
            switch (newRange) {
                case "daily":
                    return analytics.pasteAnalytics.today
                case "weekly":
                    return analytics.pasteAnalytics.week
                case "lifetime":
                    return analytics.pasteAnalytics.overall
            }
        }

        return 0
    }

    return (
        <TimeRangeCard
            title={"Key Creations"}
            units={"creations"}
            loading={!analytics}
            initialAmount={analytics?.pasteAnalytics?.today ?? 0}
            onUpdateRange={onUpdateRange}
        />
    )
}

export default KeyRevenue
