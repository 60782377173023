import { LoginResponse } from "@features/users/login/api/responses/LoginResponse"
import { MfaLoginResponse } from "@features/users/login/api/responses/MfaLoginResponse"
import Payload from "@api/Payload"
import { API, body, contentHeaders } from "@util/Api"

/**
 * Login with a username and password.
 *
 * @param username The username to log in with.
 * @param password The password to log in with.
 * @param remember If the created token from login should last more than 24 hours.
 * @param turnstile The cloudflare turnstile token.
 * @returns Either a filled out account store, with the proper login details, or a string of how the request failed.
 */
export const login = async (
    username: string,
    password: string,
    remember: boolean,
    turnstile?: string
): Promise<LoginResponse | MfaLoginResponse> => {
    let requestBody: any = { username, password, remember }

    if (turnstile) requestBody = { ...requestBody, turnstile }

    // POST base/login
    const loginRequest = await API.post(
        `/login`,
        body(requestBody),
        contentHeaders()
    )
    const { payload, message }: Payload<MfaLoginResponse | LoginResponse> =
        loginRequest.data

    if (loginRequest.status === 200 && payload) {
        // if the payload has a mfaVerification, it means it requires 2fa before fully logging in.
        if (Object.hasOwn(payload, "mfaVerification")) {
            return payload as MfaLoginResponse
        } else {
            return payload as LoginResponse
        }
    } else {
        return Promise.reject(message)
    }
}

/**
 * Login to the administrator portal with a username and password.
 *
 * @param username The administrator's username.
 * @param password The administrator's password.
 * @param turnstile Cloudflare turnstile token.
 */
export const administratorLogin = async (
    username: string,
    password: string,
    turnstile?: string
): Promise<string> => {
    let requestBody: any = { username, password }

    if (turnstile) requestBody = { ...requestBody, turnstile }

    const loginRequest = await API.post(
        `/administrator/user/login`,
        body(requestBody),
        contentHeaders()
    )

    const { payload, message }: Payload<{ token: string }> = loginRequest.data

    return loginRequest.status === 200 && payload
        ? payload.token
        : Promise.reject(message)
}
