import Spinner from "@components/Spinner"
import React, { useState } from "react"
import { Transition } from "@headlessui/react"
import { useAtom } from "jotai"
import { MobileSearchEnabled } from "@features/layout/Layout.atom"
import { GetPasteResponse } from "@features/pastes/api/models/GetPasteResponse"
import SearchModal from "@features/layout/components/search/SearchModal"
import { searchByPhrase } from "@features/layout/api/SearchUtil"

/**
 * The search bar used on small devices.
 *
 * @see Search
 * @see SearchModal
 */
const MobileSearch = () => {
    const [visible] = useAtom(MobileSearchEnabled)
    const [searchPhrase, setSearchPhrase] = useState("")

    const [searchModal, setSearchModal] = useState(false)
    const [pastes, setPastes] = useState<GetPasteResponse[]>([])
    const [loading, setLoading] = useState(false)

    // When the search button is clicked
    const onClick = async () => {
        setSearchModal(false)
        setLoading(true)

        await searchByPhrase(searchPhrase, setPastes)

        setLoading(false)
        setSearchModal(true)
    }

    return (
        <>
            <Transition
                enter="transition-all ease-in-out duration-200"
                enterFrom="opacity-0 h-0"
                enterTo="opacity-100 h-full"
                leave="transition-all ease-in-out duration-300"
                leaveFrom="opacity-100 h-full"
                leaveTo="opacity-0 h-0"
                className="w-full"
                show={visible}
            >
                <div className="md:hidden flex secondaryBackground w-full px-4 pb-4 pt-2 -mt-4">
                    <div className="bg-primaryBackground dark:bg-dark_primaryBackground text text-sm rounded-lg w-full p-2.5 border dark:border-dark_border primaryBackground flex flex-row justify-between">
                        <input
                            className="bg-transparent w-full h-full focus:outline-none"
                            onChange={(e) =>
                                setSearchPhrase(e.currentTarget.value)
                            }
                            value={searchPhrase}
                            placeholder="Enter your search query"
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    onClick().then(
                                        () => "Search has been completed!"
                                    )
                                }
                            }}
                        />

                        <span
                            className={`pl-2 ${
                                loading
                                    ? "cursor-not-allowed"
                                    : "cursor-pointer"
                            }`}
                            onClick={onClick}
                        >
                            {loading ? (
                                <Spinner className="pr-2" />
                            ) : (
                                <i className="fas fa-search pr-2"></i>
                            )}
                        </span>
                    </div>
                </div>
            </Transition>

            <SearchModal
                visible={searchModal}
                setVisible={setSearchModal}
                pastes={pastes}
                clearSearch={() => setSearchPhrase("")}
            />
        </>
    )
}

export default MobileSearch
