import { atom, useAtom } from "jotai"
import { useState } from "react"
import toast from "react-hot-toast"
import { MIN_USD_PAYOUT } from "@util/Util"
import { Link } from "react-router-dom"
import { payoutUser } from "@features/users/account/api/Payout"
import {
    account,
    accountBalance,
    getToken,
} from "@features/users/account/Account.atom"
import Modal from "@components/Modal"
import Spinner from "@components/Spinner"
import Button from "@components/inputs/Button"
import { usePropUpdateModal } from "@components/property/UpdatePropertyModal"

/**
 * Visiblity of the payout atom.
 */
export const moneroPayoutModalAtom = atom(false)

export const MoneroPayoutModal = () => {
    const [propModal, editProperty] = usePropUpdateModal()
    const [visible, setVisible] = useAtom(moneroPayoutModalAtom)
    const [loading, setLoading] = useState(false)

    const [sesToken] = useAtom(getToken) // get the session token to authorize the payout
    const [balance] = useAtom(accountBalance)
    const [acc] = useAtom(account)

    // execute the payout for the user
    const executePayout = async () => {
        setLoading(true)

        const { success, message } = await payoutUser(sesToken)

        if (success) {
            setVisible(false)
            toast.success(message)
        } else {
            toast.error(message)
        }

        setLoading(false)
    }

    return (
        <>
            <Modal
                visible={visible}
                setVisible={setVisible}
                title={
                    <>
                        Monero Payout{" "}
                        <Link
                            target="_blank"
                            className="font-normal hover:underline text-sm"
                            to={"/crypto"}
                        >
                            Learn more
                        </Link>
                    </>
                }
            >
                {balance === undefined || balance === -1 ? (
                    <Spinner />
                ) : (
                    <div className="flex flex-col gap-4">
                        <div className="flex flex-col">
                            <p>Your Monero address</p>
                            <div className="monospace break-words p-4 secondaryBackground rounded-lg">
                                <p>{acc?.payment?.paymentAddress === "" ? "No Monero Address" : acc?.payment?.paymentAddress}</p>
                            </div>
                        </div>

                        <div className="flex flex-row gap-2">
                            <Button
                                type="submit"
                                disabled={loading || MIN_USD_PAYOUT > balance || acc?.payment?.paymentAddress === ""}
                                loading={loading}
                                buttonStyle={"success"}
                                className="bg-green-500"
                                onClick={() => executePayout()}
                            >
                                <i className="fa fa-usd" /> Payout $
                                {balance.toFixed(2)}
                            </Button>

                            <Button
                                type="button"
                                buttonStyle="secondary"
                                onClick={() => {
                                    setVisible(false)
                                    editProperty(
                                        "Monero Address",
                                        "paymentAddress",
                                        false
                                    )
                                }}
                            >
                                Change Monero Address
                            </Button>
                        </div>
                    </div>
                )}
            </Modal>
            {propModal}
        </>
    )
}
