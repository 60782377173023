import { useAtom } from "jotai"
import {
    robloxUniverseId,
    withdrawalAmount,
    withdrawalProcess,
    withdrawalType,
} from "@features/users/withdrawals/Withdrawals.atom"
import Button from "@components/inputs/Button"
import Modal from "@components/Modal"
import React, { useState } from "react"
import { account, getToken } from "@features/users/account/Account.atom"
import { withdraw } from "@features/users/withdrawals/api/Withdrawals"
import toast from "react-hot-toast"
import { WithdrawalType } from "@features/users/withdrawals/api/models/WithdrawalType"
import { ROBUX_CONVERSION } from "@features/users/withdrawals/api/Robux"

/**
 * Overview details of the withdrawal.
 */
const RobuxWithdrawalOverview = () => {
    const [step, setStep] = useAtom(withdrawalProcess)
    const [universeId] = useAtom(robloxUniverseId)
    const [type] = useAtom(withdrawalType)
    const [acc, setAcc] = useAtom(account)

    const [session] = useAtom(getToken)

    const [loading, setLoading] = useState(false)

    const balance = Number(
        Math.round(parseFloat(`${acc?.payment?.balance}e2`)) + "e-2"
    )

    const [amount] = useAtom(withdrawalAmount)
    const amountUsd = Math.floor(amount / ROBUX_CONVERSION)

    const createWithdrawal = async () => {
        setLoading(true)

        const toastID = toast.loading("Purchasing game pass...")

        try {
            const withdrawRequest = await withdraw(session, type, amountUsd, universeId)

            // balance will be 0 after withdrawal :)
            setAcc((prev) => {
                return {
                    ...prev!!,
                    payment: {
                        ...prev?.payment!!,
                        balance: prev!!.payment.balance - amountUsd,
                    },
                }
            })

            toast.success(withdrawRequest)
            setStep(0)
        } catch (e) {
            toast.error(`${e}`)
        }

        setLoading(false)
        toast.dismiss(toastID)
    }

    return (
        <Modal
            visible={step === 4 && type === WithdrawalType.ROBUX}
            setVisible={() => setStep(0)}
            title={"Withdrawal Overview"}
            seriesTransitioning={true}
        >
            <div className="m-6">
                <div className="flex flex-row justify-between">
                    <p>Type</p>
                    <p className="monospace">{type}</p>
                </div>

                <div className="flex flex-row justify-between">
                    <p>Roblox Universe ID</p>
                    <p className="monospace break-all max-w-[200px]">
                        {universeId}
                    </p>
                </div>

                <div className="flex flex-row justify-between mt-4">
                    <p>Amount</p>
                    <p className="monospace">${amountUsd.toFixed(2)}</p>
                </div>

                <div className="flex flex-row justify-between">
                    <p>Robux</p>
                    <p className="monospace">{amountUsd * 100} robux</p>
                </div>

                <div className="flex flex-row justify-between">
                    <p>Robux Fees (30%)</p>
                    <p className="monospace">
                        + {Math.ceil(amountUsd * (ROBUX_CONVERSION - 100))}{" "}
                        robux
                    </p>
                </div>

                <div className="flex flex-row justify-between mt-4">
                    <p>Total</p>
                    <p className="monospace">{amount} robux</p>
                </div>

                <div className="flex flex-row justify-between mt-4">
                    <p>Remaining Balance</p>
                    <p className="monospace">${(balance - amountUsd).toFixed(2)}</p>
                </div>
            </div>

            <div className="flex flex-row justify-between">
                <Button buttonStyle="secondary" onClick={() => setStep(2)}>
                    <i className="fa-solid fa-arrow-left" /> Back
                </Button>

                <Button
                    buttonStyle="primary"
                    loading={loading}
                    onClick={createWithdrawal}
                >
                    <i className="fa-solid fa-check" /> Confirm Withdrawal
                </Button>
            </div>
        </Modal>
    )
}

export default RobuxWithdrawalOverview
