import { AdConfiguration } from "@features/users/models/AdConfiguration"
import Payload from "@api/Payload"
import { API, header, body } from "@util/Api"

/**
 * Get the amount of XMR based off a USD balance.
 *
 * @param usdBalance The USD amount.
 * @returns The amount of XMR.
 */
export const loadXmrConversion = async (
    usdBalance: number
): Promise<number> => {
    const request = await API.get(
        "https://api.kraken.com/0/public/Ticker?pair=XMRUSD"
    )

    if (request.status !== 200) return -1

    const conversion = request.data.result.XXMRZUSD.a[0] as number

    return usdBalance / conversion
}

/**
 * Load your own ad configuration.
 *
 * @param sessionToken The session token.
 */
export const loadAdConfiguration = async (
    sessionToken: string
): Promise<AdConfiguration> => {
    const request = await API.get(`/user/ads`, header(sessionToken))
    const { payload, message }: Payload<AdConfiguration> = request.data

    if (request.status === 200 && payload) {
        return payload
    } else {
        return Promise.reject(message)
    }
}

/**
 * Updates an ad type in a user's ad configuration.
 *
 * @param sessionToken The session token.
 * @param type The type of ad.
 * @param value The new value for the ad.
 */
export const updateAdConfiguration = async (
    sessionToken: string,
    type: string,
    value: boolean
): Promise<number> => {
    const request = await API.post(
        `/user/ads`,
        body({ type, value: `${value}` }),
        header(sessionToken, true)
    )
    const { payload, message }: Payload<number> = request.data

    if (request.status === 200 && payload) {
        return payload
    } else {
        return Promise.reject(message)
    }
}

/**
 * Execute a payout for the user.
 *
 * @param session The session token.
 * @param amount The amount to payout, undefined if entire balance.
 * @returns The message, undefined if error?
 */
export const payoutUser = async (
    session: string,
    amount?: number
): Promise<{ success: boolean; message: string }> => {
    const request = await API.post(
        `/user/payout`,
        body({ amount }),
        header(session, true)
    )

    const { message }: Payload<undefined> = request.data

    return {
        success: request.status === 200,
        message: message ?? "No included message.",
    }
}
