import { useAtom } from "jotai"
import { accountBalance } from "@features/users/account/Account.atom"
import {
    robuxSupply,
    withdrawalAmount,
    withdrawalProcess,
    withdrawalType,
} from "@features/users/withdrawals/Withdrawals.atom"
import {
    WithdrawalType,
    WithdrawalTypeMinimum,
} from "@features/users/withdrawals/api/models/WithdrawalType"
import Input from "@components/inputs/Input"
import React, { useEffect, useState } from "react"
import RobuxSupply from "@features/users/withdrawals/components/robux/RobuxSupply"
import Modal from "@components/Modal"
import Button from "@components/inputs/Button"
import { useMousePosition } from "@components/hooks/UseMousePosition"
import { ROBUX_CONVERSION } from "@features/users/withdrawals/api/Robux"

/**
 * A slider component that allows a user to select the amount they're going to withdraw from their account.
 */
const WithdrawalAmountSelector = () => {
    const [step, setStep] = useAtom(withdrawalProcess)

    const [supply] = useAtom(robuxSupply)

    const [type] = useAtom(withdrawalType)
    const robuxAmount = type === WithdrawalType.ROBUX
    const minimumWithdrawalAmount = robuxAmount
        ? Math.ceil(WithdrawalTypeMinimum[type] * ROBUX_CONVERSION)
        : WithdrawalTypeMinimum[type]

    const [bal] = useAtom(accountBalance)
    const balance = robuxAmount ? Math.ceil(bal * ROBUX_CONVERSION) : bal

    const [amount, setAmount] = useAtom(withdrawalAmount)

    // if the tooltip saying why you can't continue is continued
    const [tooltipEnabled, setTooltipEnabled] = useState(false)

    const continueRef = React.createRef<HTMLDivElement>()
    const [errorMessage, setErrorMessage] = useState<string>()

    const mousePosition = useMousePosition()
    useEffect(() => {
        const el = continueRef.current?.getBoundingClientRect()

        if (el !== undefined) {
            const mouseInBounds =
                mousePosition.x >= el.left &&
                mousePosition.x <= el.right &&
                mousePosition.y >= el.top &&
                mousePosition.y <= el.bottom

            setTooltipEnabled(mouseInBounds)
        }
    }, [continueRef, mousePosition, setTooltipEnabled])

    useEffect(() => {
        if (minimumWithdrawalAmount > amount) {
            // withdrawing too little
            setErrorMessage(
                `You must withdraw at least ${
                    robuxAmount
                        ? `${minimumWithdrawalAmount} robux`
                        : `$${minimumWithdrawalAmount}`
                }!`
            )
        } else if (amount > balance) {
            // withdrawing too much
            setErrorMessage(
                `You can only withdraw ${
                    robuxAmount ? `${balance} robux` : `$${balance}`
                }!`
            )
        } else if (robuxAmount && amount > supply) {
            // not enough robux :(
            setErrorMessage("Please try again later.")
        } else {
            // everything's okay
            setErrorMessage(undefined)
        }
    }, [amount, balance, minimumWithdrawalAmount, robuxAmount, supply])

    return (
        <Modal
            visible={step === 2}
            setVisible={() => setStep(0)}
            title={"Withdrawal Amount"}
            seriesTransitioning={true}
        >
            <div className="flex flex-row justify-between items-center mb-4">
                <div className="flex flex-col">
                    <label
                        htmlFor="withdrawal-range"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                        Withdrawal Amount
                    </label>
                    <p className="text-sm -mt-2">
                        Balance:{" "}
                        <span className="monospace">
                            {robuxAmount
                                ? `${balance} robux ($${bal.toFixed(2)})`
                                : `$${balance}`}
                        </span>
                        {robuxAmount && <RobuxSupply />}
                    </p>
                </div>

                <div className="flex flex-col">
                    <Input
                        iconLeft={
                            robuxAmount ? (
                                <img
                                    src="/img/robux.svg"
                                    width={32}
                                    alt="Robux Logo"
                                />
                            ) : (
                                <i className="fa-solid fa-dollar-sign text-xs" />
                            )
                        }
                        type="number"
                        className={"monospace"}
                        min={minimumWithdrawalAmount}
                        max={balance}
                        value={amount === 0 ? undefined : amount}
                        onChange={(value) => {
                            if (!isNaN(+value.currentTarget.value)) {
                                setAmount(+value.currentTarget.value)
                            } else {
                                value.preventDefault()
                            }
                        }}
                        step={robuxAmount ? 1 : 0.01}
                    />
                </div>
            </div>

            <div className="flex flex-row justify-between">
                <Button
                    buttonStyle="secondary"
                    onClick={() => {
                        setAmount(0)
                        setStep(1)
                    }}
                >
                    <i className="fa-solid fa-arrow-left" /> Back
                </Button>

                <div className="relative" ref={continueRef}>
                    <Button
                        buttonStyle="primary"
                        onClick={() => setStep(3)}
                        disabled={errorMessage !== undefined}
                    >
                        Details <i className="fa-solid fa-arrow-right" />
                    </Button>

                    {errorMessage && tooltipEnabled && (
                        <div
                            id="tooltip"
                            className="w-[140px] poppins text-xs absolute bottom-full left-1/2 transform -translate-x-1/2 island text-white rounded-md py-1 px-2 pointer-events-none transition-all duration-300 z-50"
                        >
                            {errorMessage}
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    )
}

export default WithdrawalAmountSelector
