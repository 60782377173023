import { useAtom } from "jotai"
import { forgotPasswordVisibility } from "@features/users/login/Login.atom"
import Modal from "@components/Modal"
import Input from "@components/inputs/Input"
import Button from "@components/inputs/Button"
import { useState } from "react"
import toast from "react-hot-toast"
import { Link } from "react-router-dom"
import { resetPassword } from "@features/users/account/api/PasswordReset"

/**
 * Modal when a user clicks "Lost Password"
 */
const ForgotPasswordModal = () => {
    const [visible, setVisible] = useAtom(forgotPasswordVisibility)

    const [email, setEmail] = useState<string>("")
    const [loading, setLoading] = useState(false)
    const [tries, setTries] = useState(0)

    // when the forgotten password request is sent
    const onSubmit = () => {
        setLoading(true)

        resetPassword(email)
            .then((success) => {
                if (success) {
                    toast.success(
                        "A password reset link has been sent! Please check your email."
                    )
                } else {
                    toast.error("Please wait a few moments before resending.")
                }
            })
            .then(() => setTries((tries) => tries + 1))
            .finally(() => setLoading(false))
    }

    return (
        <Modal
            visible={visible}
            setVisible={setVisible}
            title={"Forgot Password"}
        >
            <Input
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
                placeholder="Email"
            />

            <p className="my-4">
                Your email must be verified for this. Unsure?
                <Link
                    className="ml-2 underline text-blue-500 hover:text-blue-600"
                    to={"/support"}
                    onClick={() => setVisible(false)}
                >
                    Contact support.
                </Link>
            </p>

            <div className="flex flex-row gap-4">
                <Button
                    buttonStyle={"primary"}
                    onClick={onSubmit}
                    loading={loading}
                    disabled={tries > 10}
                >
                    {tries === 0 ? "Reset Password" : "Resend Email"}
                </Button>

                <Button
                    buttonStyle={"secondary"}
                    onClick={() => setVisible(false)}
                >
                    Close
                </Button>
            </div>
        </Modal>
    )
}

export default ForgotPasswordModal
