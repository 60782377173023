import { useAtom } from "jotai"
import { statusReport } from "@features/status/Status.atom"
import { isLoggedIn } from "@features/users/account/Account.atom"

/**
 * The customizable banner seen on the homepage.
 */
const BannerDisplay = () => {
    const [status] = useAtom(statusReport)
    const [loggedIn] = useAtom(isLoggedIn)
    const banner = status?.banner

    if (
        !banner ||
        banner.content === "" || // no banner exists
        (!loggedIn && banner.loggedInOnly) // if the user isn't logged in
    ) {
        return <></>
    }

    return (
        <div
            style={{ backgroundColor: banner.backgroundColor }}
            className="poppins flex flex-row items-center justify-center rounded-lg p-5 text-center"
        >
            {banner.content}
        </div>
    )
}

export default BannerDisplay
