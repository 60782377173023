import WithdrawHistory, { withdrawHistoryVisible } from "@features/dashboard/components/WithdrawHistory"
import UserPastes from "@features/dashboard/components/UserPastes"
import Statistics from "@features/dashboard/components/Statistics"

/**
 * The main page of the user dashboard.
 *
 * Allows the user to see their own pastes and the history
 * of their payouts.
 */
const Dashboard = () => {
    return (
        <div className="flex flex-col justify-evenly gap-8">
            <Statistics />

            <UserPastes />

            <WithdrawHistory />
        </div>
    )
}

export default Dashboard
