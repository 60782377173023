import { useAtom } from "jotai"
import { useNavigate } from "react-router-dom"
import { isLoggedIn } from "@features/users/account/Account.atom"
import Spinner from "@components/Spinner"
import Dash from "@features/dashboard/Dashboard"

/**
 * The /account/dashboard page.
 */
const Dashboard = () => {
    const [loggedIn] = useAtom(isLoggedIn)
    const nav = useNavigate()

    if (!loggedIn) {
        nav("/")
        return <Spinner />
    }

    return (
        <div className="flex flex-col-reverse lg:flex-row py-10 md:px-4 gap-8 md:m-4">
            <Dash />
        </div>
    )
}

export default Dashboard
