import ReferralCodeDisplay from "./ReferralCodeDisplay"
import { useReferralStatistics } from "@features/users/referrals/hooks/useReferralStatistics"
import ReferralRevenue from "@features/users/referrals/components/ReferralRevenue"
import ReferralSignups from "@features/users/referrals/components/ReferralSignups"

const Referrals = () => {
    useReferralStatistics()

    return (
        <div className="flex flex-col items-center gap-16 justify-center">
            <div className="flex flex-col text-center">
                <h1 className="text-3xl poppins">Referrals</h1>
                <p>Earn a 10% affiliate commission on the lifetime earnings of your signups.</p>
            </div>

            <div className="flex flex-col md:flex-row gap-8 items-start w-full md:w-2/3">
                <ReferralSignups/>
                <ReferralRevenue/>
            </div>

            <ReferralCodeDisplay />

            <p>Your commission will not effect a user's revenue. Funds will be automatically applied to your account.</p>
        </div>
    )
}

export default Referrals
