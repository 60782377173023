import { Link } from "react-router-dom"

const Legal = () => {
    return (
        <div className="flex justify-center items-center">
            <div className="flex flex-col max-w-2xl primaryBackground p-4 items-center rounded-lg">
                For any more questions, please send us an email:{" "}
                <a href="mailto:admin@bloxybin.com">admin@bloxybin.com</a>
                <ul className="mt-5">
                    <li>
                        <Link
                            className="text-blue-600 hover:underline"
                            to={"/legal/privacy"}
                        >
                            Privacy Policy
                        </Link>
                    </li>
                    <li>
                        <Link
                            className="text-blue-600 hover:underline"
                            to={"/legal/tos"}
                        >
                            Terms of Service
                        </Link>
                    </li>
                </ul>
                <h2 className="text-lg poppins font-bold mt-8">
                    Personal Note from Medusa:
                </h2>
                <h3 className="text-md poppins font-bold mb-8">
                    (NOT apart of Terms of Service or Privacy Policy)
                </h3>
                <p>
                    This is just a copy/paste ToS & Privacy Policy. Out goal is
                    to be a privacy preserving site that uses Monero, a private
                    & secure digital currency, & support its adoption. Yeah ik,
                    we got annoying ads on the site, but we don’t use big tech
                    ad providers which hopefully results is reduced cross-site
                    tracking. Additionally we don’t ask for intrusive personal
                    information from account sign-ups & allow for account
                    creation without an email address.
                    <br />
                    <br />
                    I encourage you to use a VPN & ad block while accessing
                    BloxyBin. IVPN & Mullvad are excellent providers & uBlock
                    Origin is arguably the best browser based ad block. I never
                    plan on using anti-ad block because while we make money off
                    ads, I too believe ads are stupid & if you want to opt out
                    you should be able too. Plus ad blocks help improve your
                    privacy.
                    <br />
                    <br />
                    Enjoy using BloxyBin & I hope you make tons of Monero!!!
                    <br />- Medusa
                </p>
            </div>
        </div>
    )
}

export default Legal
