import Property from "@components/property/Property"
import { updateAccountInformation } from "@features/users/account/api/UpdateAccount"
import toast from "react-hot-toast"
import { useAtom } from "jotai"
import { account, getToken } from "@features/users/account/Account.atom"

/**
 * Optional features on the settings dashboard
 */
const OptionalFeatures = () => {
    const [acc, setAcc] = useAtom(account)
    const [ses] = useAtom(getToken)

    // toggle the youtube embed feature
    const toggleYoutubeEmbedFeature = async () => {
        try {
            const featureStatus = acc?.profile?.youtubeEmbedFeature !== true

            await updateAccountInformation(
                ses,
                "youtubeEmbedFeature",
                `${featureStatus}`
            )

            setAcc((prev) => ({
                ...prev!!,
                profile: {
                    ...prev?.profile,
                    youtubeEmbedFeature: featureStatus,
                },
            }))
        } catch (e) {
            toast.error(`${e}`)
        }
    }

    return (
        <div className="flex flex-col lg:w-1/2 items-center justify-center">
            <h2 className="text-lg text-center poppins my-4">
                Optional Features
            </h2>

            <Property
                title={
                    <>
                        <i className="fa-brands fa-youtube"></i> Embed YouTube
                        Link in Thumbnail
                    </>
                }
                status={
                    <span className="monospace">
                        Embed{" "}
                        {acc?.profile?.youtubeEmbedFeature
                            ? "enabled"
                            : "disabled"}
                    </span>
                }
                buttonAction={toggleYoutubeEmbedFeature}
            />
        </div>
    )
}

export default OptionalFeatures
