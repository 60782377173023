import { atomWithStorage } from "jotai/utils"
import { atom } from "jotai"
import { ReferralStatisticsResponse } from "@features/users/referrals/api/responses/ReferralStatisticsResponse"

/**
 * Storage for the referral token.
 */
export const referralStorage = atomWithStorage("referral", "")

/**
 * Response for retrieving referral statistics
 */
export const referralStatistics = atom<ReferralStatisticsResponse | undefined>(
    undefined
)
