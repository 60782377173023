import { atomWithStorage } from "jotai/utils"
import { atom } from "jotai"

/**
 * The atom that stores the current theme.
 */
export const themeAtom = atomWithStorage("colorTheme", "dark")

/**
 * Get the current theme.
 */
export const getTheme = atom((get) => get(themeAtom))
