type LabelProps = {
    children: JSX.Element | JSX.Element[]
    label: string
}

/**
 * A label for an {@link Input}.
 *
 * {@param children} should include an Input.
 *
 * @param label The label for the Input.
 */
export default function Label({ label, children }: LabelProps) {
    return <label className="flex flex-col">
        {label}
        {children}
    </label>
}