import { API } from "@util/Api"

/**
 * Validate if a password reset token is valid.
 * @param token The token to valdiate.
 */
export const validateResetToken = async (token: string): Promise<boolean> => {
    const response = await API.get(`/user/reset?token=${token}`)

    return response.status === 200 && response.data.payload === true
}

/**
 * Create a password reset call.
 * @param email The email to create the request for.
 */
export const resetPassword = async (email: string) => {
    const formData = new FormData()

    formData.set("email", email)

    const response = await API.post(
        `/user/reset`,
        new URLSearchParams(formData as any)
    )

    return response.status === 200
}

/**
 * Finalize the password reset process.
 *
 * @param token The password reset token.
 * @param newPassword The new password to change to.
 */
export const finalizePasswordReset = async (token: string, newPassword: string) => {
    const formData = new FormData()

    formData.set("token", token)
    formData.set("password", newPassword)

    const response = await API.put(`/user/reset`, new URLSearchParams(formData as any).toString())

    return response.status === 200
}