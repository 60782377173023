import Property from "@components/property/Property"
import {useAtom} from "jotai"
import {account} from "@features/users/account/Account.atom"
import SettingsSection from "@features/dashboard/components/settings/sections/SettingsSection"

const MonetizationSettings = ({editProperty}: SettingsSection) => {
    const [acc] = useAtom(account)

    const moneroPaymentAddress = acc?.payment?.paymentAddress
    const moneroStatus =
        moneroPaymentAddress === "" || !moneroPaymentAddress
            ? "No Monero (XMR) address set!"
            : `${moneroPaymentAddress.substring(
                0,
                8
            )}...${moneroPaymentAddress.substring(
                moneroPaymentAddress.length - 8,
                moneroPaymentAddress.length
            )}`

    return (
        <>
            <div>
                <h2 className="text-lg text-center poppins my-4">
                    Monetization Settings
                </h2>
                <div className="flex flex-col gap-8 items-center justify-center">
                    <div className="flex flex-col md:flex-row justify-between w-full gap-8">
                        {/* Monero Withdrawal Address */}
                        <div className="flex flex-col space-y-4 w-full">
                            <Property
                                title={
                                    <>
                                        <i className="fab fa-monero"/>{" "}
                                        Withdrawal Address
                                    </>
                                }
                                status={moneroStatus}
                                buttonAction={() =>
                                    editProperty(
                                        "Monero Address",
                                        "paymentAddress",
                                        false,
                                        acc?.payment?.paymentAddress
                                    )
                                }
                            />
                        </div>
                    </div>
                </div>

                {/* PayPal Withdrawal Address */}
                <div className="space-y-4 mt-4 flex items-center justify-center">
                    <div className="w-full lg:w-1/2">
                        <Property
                            title={
                                <>
                                    <i className="fab fa-paypal"/> Paypal
                                    Withdrawal Address
                                </>
                            }
                            status={
                                acc?.payment?.paypalAddress === "" ||
                                !acc?.payment?.paypalAddress
                                    ? "No PayPal address set!"
                                    : acc?.payment?.paypalAddress
                            }
                            buttonAction={() =>
                                editProperty(
                                    "PayPal Address",
                                    "paypalAddress",
                                    false,
                                    acc?.payment?.paypalAddress
                                )
                            }
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default MonetizationSettings
