interface SpinnerProps {
    className?: string
}

/**
 * A spinner.
 */
const Spinner = ({ className }: SpinnerProps) => {
    return <i className={`${className} fa fa-spinner spinner`} />
}

export default Spinner
