import React, { useEffect } from "react"
import { getRobuxSupply } from "@features/users/withdrawals/api/Robux"
import { useAtom } from "jotai"
import { robuxSupply } from "@features/users/withdrawals/Withdrawals.atom"

/**
 * The amount of Robux we currently have.
 */
const RobuxSupply = () => {
    const [robux, setRobux] = useAtom(robuxSupply)

    useEffect(() => {
        getRobuxSupply().then((robuxSupply) => setRobux(robuxSupply))
    }, [setRobux])

    return (
        <>
            <br />
            Supply:{" "}
            <span className="monospace">{robux?.toLocaleString()} robux</span>
        </>
    )
}

export default RobuxSupply
