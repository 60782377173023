import { API } from "@util/Api"

/**
 * The conversion factor from USD to Robux.
 */
export const ROBUX_CONVERSION = 142.857143

/**
 * Get the current amount of Robux.
 */
export const getRobuxSupply = async (): Promise<number> => {
    const request = await API.get("/user/withdrawal/robux/supply")

    return request.data.payload
}