/**
 * Information about Crypto
 */
const Crypto = () => {
    return (
        <div className="island p-6 rounded-lg mt-8 cryptoPage">
            <div className="mb-6 max-w-6xl">
                <h1 className="poppins text-2xl mb-2">Why Do We Use Crypto?</h1>

                <p>
                    We use crypto because it is accessible to everyone no matter
                    your country, age, or anything else. Plus funds cannot be
                    frozen unlike PayPal. To use cryptocurrency all you need is
                    an internet connection.
                </p>
                <br />
                <p>
                    BloxyBin pays out users in a privacy protecting
                    cryptocurrency called Monero, aka XMR. Unlike other cryptos
                    yours transactions cannot be tracked with Monero.
                </p>
            </div>

            <div className="mb-6">
                <h1 className="poppins text-2xl mb-2">
                    How Do I Withdraw Crypto?
                </h1>
                <ol className="list-decimal pl-4">
                    <li>
                        Download an crypto wallet application & create a Monero
                        wallet. We recommend{" "}
                        <a
                            href="https://cakewallet.com"
                            rel="noreferrer nooppener"
                            target="_blank"
                        >
                            Cake Wallet
                        </a>{" "}
                        (Android & iOS) or the official{" "}
                        <a
                            href="https://www.getmonero.org/downloads/#gui"
                            rel="noreferrer noopenner"
                            target="_blank"
                        >
                            {" "}
                            Monero Wallet
                        </a>{" "}
                        (Windows, Mac, Linux).
                    </li>

                    <li>
                        Copy the XMR address to your wallet & paste it on
                        BloxyBin.
                    </li>

                    <li>Withdraw your funds!</li>
                </ol>
            </div>

            <div className="">
                <h1 className="poppins text-2xl mb-2">What's Next?</h1>
                <p>
                    Congratulations, you have now withdrawn Monero & are a proud
                    owner of crypto currency! Well what can you actually do with
                    it?
                </p>

                <br />

                <p>
                    Well buy stuff of course! Did you know{" "}
                    <a href="https://x.synapse.to/">Synapse</a> offers a
                    discount if you pay in crypto? You can also purchase Robux
                    with Monero on sites like{" "}
                    <a href="https://coincards.com/us/product/roblox-online-gift-card-electronic-delivery/">
                        CoinCards
                    </a>{" "}
                    or{" "}
                    <a href="https://cryptwerk.com/market/roblox-pets-and-robux/monero/">
                        cryptwerk
                    </a>
                    , plus most script developers who offer premium
                    subscriptions accept payment in crypto.
                </p>

                <br />

                <p>
                    You can buy many other things as well, such as a VPN
                    subscription from <a href="https://www.ivpn.net/">IVPN</a>{" "}
                    or <a href="https://mullvad.net/">Mullvad</a>,{" "}
                    <a href="https://1984.hosting/">website hosting</a>, &{" "}
                    <a href="https://coincards.com/us/">gift cards</a> to all
                    your favorite stores.
                </p>

                <br />

                <p>
                    Just want money? Sell Monero for your own local currency!
                    The best option is{" "}
                    <a href="https://localmonero.co/">LocalMonero</a> which is
                    private & you don’t need to be 18+ or have ID to use it.
                    Another option is{" "}
                    <a href="https://www.kraken.com/">Kraken</a> which is a
                    popular & trusted cryptocurrency exchange that operates
                    globally, but you’ll need ID to use it.
                </p>

                <br />

                <p>
                    At the end of the day, Monero is just private digital cash,
                    use it how you would other any other form of money.
                </p>
            </div>
        </div>
    )
}

export default Crypto
