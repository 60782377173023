import { useAtom } from "jotai"
import {
    withdrawalProcess,
    withdrawalType,
} from "@features/users/withdrawals/Withdrawals.atom"
import Modal from "@components/Modal"
import { account, getToken } from "@features/users/account/Account.atom"
import Input from "@components/inputs/Input"
import React, { useEffect, useMemo, useState } from "react"
import { WithdrawalType } from "@features/users/withdrawals/api/models/WithdrawalType"
import Button from "@components/inputs/Button"
import { updateAccountInformation } from "@features/users/account/api/UpdateAccount"
import toast from "react-hot-toast"
import WithdrawalAmountSelector from "@features/users/withdrawals/components/WithdrawalAmountSelector"
import { Link } from "react-router-dom"

/**
 * Confirm details for withdrawal.
 *
 * This is for both PayPal and Monero withdrawals.
 */
const WithdrawalConfirmDetails = () => {
    const [step, setStep] = useAtom(withdrawalProcess)
    const [type] = useAtom(withdrawalType)

    const [loading, setLoading] = useState<boolean>(false)

    const [sesToken] = useAtom(getToken)
    const [acc, setAcc] = useAtom(account)
    const [paymentAddress, setPaymentAddress] = useState<string>()

    // the existing address on the account
    const existingAddress =
        type === WithdrawalType.PAYPAL
            ? acc?.payment?.paypalAddress
            : acc?.payment?.paymentAddress

    useEffect(() => {
        switch (type) {
            case WithdrawalType.PAYPAL:
                setPaymentAddress(acc?.payment?.paypalAddress)
                break
            case WithdrawalType.MONERO:
                setPaymentAddress(acc?.payment?.paymentAddress)
                break
        }
    }, [type, acc])

    const updatePaymentAddress = async () => {
        setLoading(true)

        if (!paymentAddress) {
            toast.error("Invalid payment address!")
            setLoading(false)
            return
        }

        let key: string

        switch (type) {
            case WithdrawalType.PAYPAL:
                key = "paypalAddress"
                break

            case WithdrawalType.MONERO:
                key = "paymentAddress"
                break

            // shouldn't happen
            default:
                return
        }

        try {
            await updateAccountInformation(sesToken, key, paymentAddress)

            switch (type) {
                case WithdrawalType.PAYPAL:
                    setAcc((prev) => {
                        prev!!.payment!!.paypalAddress = paymentAddress
                        return prev
                    })
                    break

                case WithdrawalType.MONERO:
                    setAcc((prev) => {
                        prev!!.payment!!.paymentAddress = paymentAddress
                        return prev
                    })
                    break
                // shouldn't happen
                default:
                    return
            }

            toast.success("Successfully updated payment address!")
        } catch (e) {
            toast.error(`${e}`)
        }

        setLoading(false)
    }

    return (
        <Modal
            visible={step === 3 && type !== WithdrawalType.ROBUX}
            setVisible={() => setStep(0)}
            title={"Confirm Details"}
            seriesTransitioning={true}
        >
            {existingAddress === "" || existingAddress === undefined ? (
                <p className="text-center">
                    <i className="fa-solid fa-warning" /> You currently don't
                    have a payment address set.
                    <br />
                    You must set one to proceed.
                </p>
            ) : (
                <></>
            )}

            <div className="flex flex-row my-4 gap-4 justify-center">
                <Input
                    value={paymentAddress}
                    placeholder="Payment Address"
                    onChange={(e) => setPaymentAddress(e.currentTarget.value)}
                />

                <Button
                    buttonStyle="success"
                    value={paymentAddress}
                    onClick={updatePaymentAddress}
                >
                    Update
                </Button>
            </div>

            <WithdrawalAmountSelector />

            <p className="text-center mb-4 text-blue-500 underline">
                <Link to={"/crypto"}>Learn more about Monero & Crypto.</Link>
            </p>

            <div className="flex flex-row justify-between">
                <Button buttonStyle="secondary" onClick={() => setStep(2)}>
                    <i className="fa-solid fa-arrow-left" /> Back
                </Button>

                <Button
                    buttonStyle="primary"
                    onClick={() => setStep(4)}
                    disabled={existingAddress === "" || existingAddress === undefined}
                    loading={loading}
                >
                    Confirmation <i className="fa-solid fa-arrow-right" />
                </Button>
            </div>
        </Modal>
    )
}

export default WithdrawalConfirmDetails
