import { useAtom } from "jotai"
import { referralStatistics } from "@features/users/referrals/Referrals.atom"
import { useEffect } from "react"
import { getToken } from "@features/users/account/Account.atom"
import { getReferralStatistics } from "@features/users/referrals/api/ReferralStatistics"
import toast from "react-hot-toast"

/**
 * Automatically load the referralStatistics atom
 */
export const useReferralStatistics = () => {
    const [session] = useAtom(getToken)
    const [referralStats, setReferralStats] = useAtom(referralStatistics)

    useEffect(() => {
        if (!referralStats) {
            getReferralStatistics(session)
                .then((obj) => setReferralStats(obj))
                .catch((msg) =>
                    toast.error(
                        `There has been an issue loading referral statistics! ${msg}`
                    )
                )
        }
    }, [referralStats])
}
