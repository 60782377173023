import SettingsSection from "@features/dashboard/components/settings/sections/SettingsSection"
import Property from "@components/property/Property"
import Spinner from "@components/Spinner"
import { useAtom } from "jotai"
import { account, mfaToggleStatus, mfaToggleVisible } from "@features/users/account/Account.atom"

/**
 * Manages a user's account settings.
 */
const AccountSettings = ({ editProperty }: SettingsSection) => {
    const [acc] = useAtom(account)

    // the 2fa stuff
    const [, setMfaVisible] = useAtom(mfaToggleVisible) // if visible
    const [, setMfaStatus] = useAtom(mfaToggleStatus) // if enabling or disabling

    // get the user's current email and verification status
    const currentEmail = () => {
        if (acc?.emailVerified === true && acc?.email) {
            return acc?.email
        } else if (acc?.email) {
            return (
                <>
                    <i className="fas fa-exclamation-triangle"></i> {acc.email}
                </>
            )
        } else return `No email`
    }


    // Changes the 2FA modal to either enable or disable 2FA status
    const mfaButton = () => {
        const newStatus = acc?.mfaEnabled ?? false

        setMfaStatus(!newStatus)
        setMfaVisible(true)
    }

    return <div>
        <h2 className="text-lg text-center poppins mb-4">
            Account Settings
        </h2>
        <div className="flex flex-col md:flex-row justify-between w-full gap-8">
            <div className="flex flex-col space-y-4 w-full">
                <Property
                    title="Username"
                    status={acc?.username ?? <Spinner />}
                    buttonAction={() =>
                        editProperty("Username", "username", false)
                    }
                />

                <Property
                    title="E-Mail"
                    status={currentEmail()}
                    breakAll={true}
                    buttonAction={() =>
                        editProperty("E-Mail", "email", false)
                    }
                />
            </div>
            <div className="flex flex-col space-y-4 w-full">
                <Property
                    title="Password"
                    status="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                    buttonAction={() =>
                        editProperty("Password", "password", true)
                    }
                />

                <Property
                    title="Two-Factor Authentication"
                    status={
                        acc?.mfaEnabled
                            ? "Two factor enabled"
                            : "Two factor disabled"
                    }
                    buttonAction={mfaButton}
                />
            </div>
        </div>
    </div>
}

export default AccountSettings