import { LoginResponse } from "@features/users/login/api/responses/LoginResponse"
import { API, body, contentHeaders } from "@util/Api"
import Payload from "@api/Payload"

/**
 * Register with a username, password, and confirm password.
 *
 * @param username The username for the account.
 * @param password The password for the account.
 * @param turnstile The Cloudflare turnstile token.
 * @param referral An optional referral.
 */
export const register = async (
    username: string,
    password: string,
    turnstile: string,
    referral?: string
): Promise<LoginResponse> => {
    let requestBody: any = {
        username,
        password,
        turnstile,
    }

    if (referral) {
        requestBody = {
            ...requestBody,
            referral,
        }
    }

    const registerRequest = await API.put(
        `/user`,
        body(requestBody),
        contentHeaders()
    )
    const { payload, message }: Payload<LoginResponse> = registerRequest.data

    return registerRequest.status === 200 && payload
        ? payload
        : Promise.reject(message)
}
