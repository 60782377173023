/**
 * An input wrapper with custom styles.
 */
const TextArea: React.FC<
    React.DetailedHTMLProps<
        React.TextareaHTMLAttributes<HTMLTextAreaElement>,
        HTMLTextAreaElement
    >
> = (props) => {
    return (
        <textarea
            {...props}
            className={`${
                props.className ? props.className : ""
            } textAreaInput`}
        />
    )
}

export default TextArea
