const ToS = () => {
    return (
        <div className="flex justify-center items-center">
            <div className="flex flex-col max-w-2xl primaryBackground p-4 items-center rounded-lg">
                <div className="my-4">
                    <h1 className="text-5xl poppins text-bold m-4">
                        Terms of Service:
                    </h1>

                    <p>
                        Pastes containing or linking to any of the following is
                        forbidden & a violation of our terms of service:
                    </p>

                    <ul className="list-disc ml-5">
                        <li>email lists</li>
                        <li>login details</li>
                        <li>
                            copyrighted works or links to copyrighted works you
                            do not own the copyright for
                        </li>
                        <li>password lists</li>
                        <li>credit card data, bank information</li>
                        <li>personal information or data</li>
                        <li>pornographic content</li>
                        <li>
                            links to 'spam' websites or otherwise spammy content
                        </li>
                        <li>threats or harassment</li>
                        <li>
                            any material which is not legal in the United States
                            or El Salvador
                        </li>
                    </ul>
                </div>

                <h1 className="text-5xl poppins text-bold mt-4">
                    Web Site Terms & Conditions of Use
                </h1>

                <ol>
                    <li>
                        <h2 className="poppins text-2xl text-bold mt-4">
                            1. Terms
                        </h2>
                        <p>
                            By accessing this web site, you are agreeing to be
                            bound by these web site Terms & Conditions of Use,
                            all applicable laws & regulations, & agree that you
                            are responsible for compliance with any applicable
                            local laws. If you do not agree with any of these
                            terms, you are prohibited from using or accessing
                            this site. The materials contained in this web site
                            are protected by applicable copyright & trade mark
                            law.
                        </p>
                    </li>
                    <li>
                        <h2 className="poppins text-2xl text-bold mt-4">
                            1. Use License{" "}
                        </h2>
                        <ol className="ml-4">
                            <li className="mb-2">
                                <p>
                                    a. Permission is granted to temporarily
                                    download one copy of the materials
                                    (information or software) on BloxyBin's web
                                    site for personal, non-commercial transitory
                                    viewing only. This is the grant of a
                                    license, not a transfer of title, & under
                                    this license you may not:
                                </p>
                                <p className="ml-4">
                                    i. modify or copy the materials;
                                    <br />
                                    ii. use the materials for any commercial
                                    purpose, or for any public display
                                    (commercial or non-commercial);
                                    <br />
                                    iii. attempt to decompile or reverse
                                    engineer any software contained on
                                    BloxyBin's web site;
                                    <br />
                                    iv. remove any copyright or other
                                    proprietary notations from the materials; or
                                    <br />
                                    v. transfer the materials to another person
                                    or "mirror" the materials on any other
                                    server.
                                </p>
                            </li>

                            <li>
                                b. This license shall automatically terminate if
                                you violate any of these restrictions & may be
                                terminated by BloxyBin at any time. Upon
                                terminating your viewing of these materials or
                                upon the termination of this license, you must
                                destroy any downloaded materials in your
                                possession whether in electronic or printed
                                format.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2 className="poppins text-2xl text-bold mt-4">
                            3. Disclaimer
                        </h2>
                        <p>
                            The materials on BloxyBin's web site are provided
                            "as is". BloxyBin makes no warranties, expressed or
                            implied, & hereby disclaims & negates all other
                            warranties, including without limitation, implied
                            warranties or conditions of merchantability, fitness
                            for a particular purpose, or non-infringement of
                            intellectual property or other violation of rights.
                            Further, BloxyBin does not warrant or make any
                            representations concerning the accuracy, likely
                            results, or reliability of the use of the materials
                            on its Internet web site or otherwise relating to
                            such materials or on any sites linked to this site.
                        </p>
                    </li>
                    <li>
                        <h2 className="poppins text-2xl text-bold mt-4">
                            4. Limitations{" "}
                        </h2>
                        <p>
                            In no event shall BloxyBin or its suppliers be
                            liable for any damages (including, without
                            limitation, damages for loss of data or profit, or
                            due to business interruption,) arising out of the
                            use or inability to use the materials on BloxyBin's
                            Internet site, even if BloxyBin or a BloxyBin
                            authorized representative has been notified orally
                            or in writing of the possibility of such damage.
                            Because some jurisdictions do not allow limitations
                            on implied warranties, or limitations of liability
                            for consequential or incidental damages, these
                            limitations may not apply to you.
                        </p>
                    </li>
                    <li>
                        <h2 className="poppins text-2xl text-bold mt-4">
                            5. Revisions & Errata
                        </h2>
                        <p>
                            The materials appearing on BloxyBin's web site could
                            include technical, typographical, or photographic
                            errors. BloxyBin does not warrant that any of the
                            materials on its web site are accurate, complete, or
                            current. BloxyBin may make changes to the materials
                            contained on its web site at any time without
                            notice. BloxyBin does not, however, make any
                            commitment to update the materials.
                        </p>
                    </li>
                    <li>
                        <h2 className="poppins text-2xl text-bold mt-4">
                            6. Links
                        </h2>
                        <p>
                            BloxyBin has not reviewed all of the sites linked to
                            its Internet web site & is not responsible for the
                            contents of any such linked site. The inclusion of
                            any link does not imply endorsement by BloxyBin of
                            the site. Use of any such linked web site is at the
                            user's own risk.
                        </p>
                    </li>
                    <li>
                        <h2 className="poppins text-2xl text-bold mt-4">
                            7. Site Terms & Conditions Modifications{" "}
                        </h2>
                        <p>
                            BloxyBin may revise these terms of use for its web
                            site at any time without notice. By using this web
                            site you are agreeing to be bound by the most up to
                            date version of these Terms & Conditions.{" "}
                        </p>
                    </li>
                    <li>
                        <h2 className="poppins text-2xl text-bold mt-4">
                            8. Governing Law
                        </h2>
                        <p>
                            Any claim relating to BloxyBin's web site shall be
                            governed by the laws of El Salvador without regard
                            to its conflict of law provisions
                        </p>
                    </li>
                    <li>
                        <h2 className="poppins text-2xl text-bold mt-4">
                            9. Paste data collection & privacy
                        </h2>
                        <p>
                            Assume any data you post to this server to be
                            self-published materials that will be publicly
                            visible & remain forever.
                        </p>
                    </li>
                    <li>
                        <h2 className="poppins text-2xl text-bold mt-4">
                            10. Copyright limitations
                        </h2>
                        <p>
                            All trademarks & copyrights are owned by their
                            respective owners. Pastes are owned by their
                            creator.
                        </p>
                    </li>
                    <li>
                        <h2 className="poppins text-2xl text-bold mt-4">
                            11. Acceptable content
                        </h2>
                        <p>
                            You agree that you will not post information or link
                            to any information that is libelous, nor may it be
                            illegal activity in any country. You may not
                            impersonate any individual or group, real or
                            fictitious, if asked to provide personally
                            identifying information. You may not use this
                            website for any purpose which would be deemed
                            illegal under united states law. You agree that you
                            will not hold BloxyBin or its operators responsible
                            for any damages that may result from direct or
                            indirect usage of this website, or for any other
                            operations of this website. You acknowledge that
                            there is no guarantee as to the suitability of this
                            website for any specific purpose. The Terms of
                            Service may change at any time without notification.
                        </p>
                    </li>
                </ol>
            </div>
        </div>
    )
}

export default ToS
