import { ReferralStatisticsResponse } from "@features/users/referrals/api/responses/ReferralStatisticsResponse"
import { API, header } from "@util/Api"
import Payload from "@api/Payload"

/**
 * Get a user's referral statistics.
 *
 * @param sessionToken The current usre's session token.
 */
export const getReferralStatistics = async (
    sessionToken: string
): Promise<ReferralStatisticsResponse> => {
    const request = await API.get(
        `/user/stats/referral`,
        header(sessionToken)
    )
    const { payload, message }: Payload<ReferralStatisticsResponse> =
        request.data

    return request.status === 200 && payload ? payload : Promise.reject(message)
}
