import React, { useState } from "react"
import { GetPasteResponse } from "@features/pastes/api/models/GetPasteResponse"
import Spinner from "@components/Spinner"
import SearchModal from "@features/layout/components/search/SearchModal"
import { searchByPhrase } from "@features/layout/api/SearchUtil"

/**
 * The search bar in the header.
 *
 * @see SearchModal
 * @see SearchModal
 */
const Search = () => {
    const [searchModal, setSearchModal] = useState(false)
    const [pastes, setPastes] = useState<GetPasteResponse[]>([])
    const [loading, setLoading] = useState(false)

    // the current search query
    const [searchPhrase, setSearchPhrase] = useState("")

    // When the search button is clicked
    const onClick = async () => {
        setSearchModal(false)
        setLoading(true)

        await searchByPhrase(searchPhrase, setPastes)

        setLoading(false)

        setSearchModal(true)
    }

    return (
        <div className="md:flex hidden flex-row justify-center items-center gap-4 w-1/2">
            {/* The search bar */}
            <div className="bg-primaryBackground dark:bg-dark_primaryBackground text text-sm rounded-lg w-full p-2.5 border dark:border-dark_border primaryBackground flex flex-row justify-between">
                <input
                    className="bg-transparent w-full h-full focus:outline-none"
                    onChange={(e) => setSearchPhrase(e.currentTarget.value)}
                    value={searchPhrase}
                    placeholder="Enter your search query"
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            onClick().then(() => "Search has been completed!")
                        }
                    }}
                />

                <span
                    className={`pl-2 ${
                        loading ? "cursor-not-allowed" : "cursor-pointer"
                    }`}
                    onClick={onClick}
                >
                    {loading ? (
                        <Spinner className="pr-2" />
                    ) : (
                        <i className="fas fa-search pr-2"></i>
                    )}
                </span>
            </div>

            <SearchModal
                visible={searchModal}
                setVisible={setSearchModal}
                pastes={pastes}
                clearSearch={() => setSearchPhrase("")}
            />
        </div>
    )
}

export default Search
