import { UserVipPreferences } from "@features/users/account/api/models/UserVipPreferences"
import { API, header } from "@util/Api"
import Payload from "@api/Payload"

/**
 * Get a user's VIP preferences
 *
 * @param session The session token
 * @return The user's VIP preferences
 */
export const getVip = async (session: string): Promise<UserVipPreferences> => {
    const request = await API.get(`/user/vip`, header(session))
    const { payload, message }: Payload<UserVipPreferences> = request.data

    return request.status === 200 && payload ? payload : Promise.reject(message)
}
