import Spinner from "@components/Spinner"
import { usePropUpdateModal } from "@components/property/UpdatePropertyModal"
import { useAtom } from "jotai"
import UpdateProfilePicture, {
    updateProfilePictureVisibility,
} from "./UpdateProfilePicture"
import { account } from "@features/users/account/Account.atom"
import Property from "@components/property/Property"

const Profile = () => {
    const [, setPfp] = useAtom(updateProfilePictureVisibility)

    const [propModal, editProperty] = usePropUpdateModal()

    const [acc] = useAtom(account)

    if (!acc) {
        return <Spinner />
    }

    return (
        <div className="flex space-y-8 flex-col md:flex-row w-full flex-start items-center gap-16 bg-transparent	">
            {propModal}
            <UpdateProfilePicture />

            {/* Profile picture management */}
            <div className="bg-dark_primaryBackground rounded-lg p-4">
                <div className="p-4 rounded-lg relative mx-auto w-48 h-48">
                    <div className={"flex items-center justify-center"}>
                        <img
                            src={
                                Object.hasOwn(acc.profile, "profilePicture")
                                    ? acc.profile.profilePicture
                                    : "/img/logo512.png"
                            }
                            className="rounded-full"
                            alt="User profile picture"
                        />
                    </div>

                    <svg
                        onClick={() => setPfp((prev) => !prev)}
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="absolute right-2 bottom-2 fill-gray-400 hover:fill-white transition-colors hover:cursor-pointer"
                    >
                        <circle cx="20" cy="20" r="20" fill="#232323" />
                        <path d="M23 13L24.497 11.5273C25.2844 10.7526 26.561 10.7526 27.3484 11.5273L29.4467 13.5916C30.2341 14.3662 30.2341 15.6222 29.4467 16.3968L27.9497 17.8696L23 13Z" />
                        <rect
                            width="6.94353"
                            height="10.9113"
                            transform="matrix(0.712857 0.701309 -0.712857 0.701309 22 14)"
                        />
                        <path d="M11.4305 29.2402L13.2237 22.6565L18.1226 27.4761L11.4305 29.2402Z" />
                    </svg>
                </div>
            </div>

            <div className="flex flex-col w-full gap-4">
                <Property
                    title="Discord Link"
                    status={acc?.profile?.discordLink ?? "None set"}
                    buttonAction={() => {
                        editProperty("Discord Link", "discordLink", false)
                    }}
                />

                <Property
                    title="YouTube Link"
                    status={acc?.profile?.youtubeLink ?? "None set"}
                    buttonAction={() =>
                        editProperty("YouTube Link", "youtubeLink", false)
                    }
                />

                <Property
                    title="Description"
                    status={acc?.profile?.description ?? "None set"}
                    buttonAction={() =>
                        editProperty("Description", "description", false)
                    }
                />
            </div>
        </div>
    )
}

export default Profile
