import { useNavigate, useSearchParams } from "react-router-dom"
import { useAtom } from "jotai"
import Spinner from "@components/Spinner"
import { referralStorage } from "@features/users/referrals/Referrals.atom"

/**
 * An alias for the register page. This allows for https://bloxybin.com/r?referral
 */
const RegisterAlias = () => {
    const [searchParams] = useSearchParams()
    const nav = useNavigate()

    let keys = Array.from(searchParams.keys())
    const [, setReferral] = useAtom(referralStorage)

    if (keys.length > 0) setReferral(keys[0])

    nav("/register")
    return <Spinner />
}

export default RegisterAlias