import { useEffect } from "react"
import { useNavigate, useRouteError } from "react-router-dom"
import { BASE_URL } from "@util/Util"
import { API, body, contentHeaders } from "@util/Api"

/**
 * Error element
 */
const ErrorElement = () => {
    const error = useRouteError()
    const nav = useNavigate()

    useEffect(() => {
        const interval = setInterval(() => {
            nav("/")
        }, 5000)

        return () => clearInterval(interval)
    }, [nav])

    useEffect(() => {
        const errorReporter = async () => {
            await API.post(
                `${BASE_URL}/feedback/error`,
                body({ error }),
                contentHeaders()
            )
        }

        errorReporter().finally(() =>
            console.log("Error has been reported, thank you for your patience.")
        )
    }, [error])

    return (
        <div className="flex flex-col mt-48 justify-center items-center">
            <div className="secondaryBackground p-4 rounded-lg">
                <h1 className="poppins text-2xl">
                    There was an issue with that request.
                </h1>
                <p>You will be redirected home momentarily...</p>
            </div>
        </div>
    )
}

export default ErrorElement
