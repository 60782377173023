import React, { useEffect, useState } from "react"
import Spinner from "@components/Spinner"
import { PropertyProps } from "@components/property/PropertyProps"
import toast from "react-hot-toast"

/**
 * Props for a modifiable property.
 */
interface ModifiablePropertyProps extends PropertyProps {
    value?: string
    setValue: (newValue: string) => void
    onSave: () => Promise<void>
    editText?: JSX.Element | JSX.Element[] | string
    completeText?: JSX.Element | JSX.Element[] | string
    inputProps?: React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    >
}

/**
 * A property of modifiable.
 */
export const ModifiableProperty: React.FC<ModifiablePropertyProps> = (
    props
) => {
    const [initialValue, setInitialValue] = useState(props.value)

    const [editing, setEditing] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!editing && props.value && initialValue !== props.value) {
            setLoading(true)
            setInitialValue(props.value)

            props.onSave().finally(() => setLoading(false))
        }
    }, [editing, initialValue, props])

    return (
        <div className="acc-set-column bg-primaryBackground dark:bg-dark_primaryBackground p-4 rounded-lg">
            <div className="flex justify-between gap-2">
                <div className="flex flex-col">
                    <span className="text-sm text-gray-500 font-medium">
                        {props.title}
                    </span>

                    {!editing ? (
                        <span className="font-semibold break-words max-w-xs text-lg text-black dark:text-white">
                            {props.status}
                        </span>
                    ) : (
                        <div className="flex flex-row items-center ring-1 px-2 rounded-md">
                            <i className="fa-solid fa-pencil" />
                            <input
                                className="bg-transparent !outline-none rounded-md pl-2 font-semibold max-w-xs text-lg text-black dark:text-white"
                                value={props.value}
                                disabled={loading}
                                onChange={(e) =>
                                    props.setValue(e.currentTarget.value)
                                }
                                {...props.inputProps}
                            />
                            {loading && <Spinner />}
                        </div>
                    )}
                </div>

                <button
                    type="button"
                    className="acc-set-button self-start mt-1"
                    onClick={() => setEditing((prev) => !prev)}
                >
                    {editing ? props.completeText : props.editText}
                </button>
            </div>
        </div>
    )
}

export default ModifiableProperty
