import React, { useState } from "react"
import { useAtom } from "jotai"
import { MobileSearchEnabled } from "@features/layout/Layout.atom"

const MobileSearchButton = () => {
    const [, setSearchVisible] = useAtom(MobileSearchEnabled)

    return <button
        className="nav-btn md:hidden"
        id="mobile-search-button"
        onMouseDown={() => setSearchVisible(prev => !prev)}
    >
        <i className="fas fa-search text-lg"></i>
    </button>
}

export default MobileSearchButton