import React from "react"

/**
 * Paginates through content.
 */
const Paginator: React.FC<{
    currentPage: number // the current page
    setCurrentPage: (newPage: number) => void // changes the current page
    maxPage: number // the highest currentPage can go
    totalResults: number // the total results
    pageCount: number // the amount of items per page.
    className?: string // styling stuff :)
    disableResultsSummary?: boolean // if the paginator shouldn't show the "Showing x to x of x results"
}> = ({
    currentPage,
    setCurrentPage,
    maxPage,
    pageCount,
    totalResults,
    className,
    disableResultsSummary,
}) => {
    // for the "Showing x to x of x results" display.
    // ex: for page 1, (1 - 1) * 50, so it's starting at 0. then, end num is + 50. so showing items 0 to 50.
    const startNum = (currentPage - 1) * pageCount // the start item count
    let endNum = startNum + pageCount // the end item count

    // if endnum is predicted to go past the total results, just return total results
    if (endNum > totalResults) endNum = totalResults

    return (
        <div
            className={`flex flex-col justify-center items-center rounded ${
                !disableResultsSummary ? `p-6` : ``
            }`}
        >
            {!disableResultsSummary && (
                <p className="poppins mb-2">
                    Showing <b>{startNum}</b> to <b>{endNum}</b> of{" "}
                    <b>{totalResults}</b> results.
                </p>
            )}

            {/* Prev and Next buttons */}
            <div
                className={`inline-flex ${
                    !disableResultsSummary ? `mt-2` : ``
                } ${className} xs:mt-0`}
            >
                <button
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                    className={`disabled:cursor-not-allowed inline-flex items-center px-4 py-2 text-sm font-medium rounded-l text secondaryHoverPrimary`}
                >
                    <svg
                        aria-hidden="true"
                        className="w-5 h-5 mr-2"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                            clipRule="evenodd"
                        ></path>
                    </svg>
                    Prev
                </button>

                <button
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage === maxPage}
                    className="disabled:cursor-not-allowed inline-flex items-center px-4 py-2 text-sm font-medium  border-0 border-l border-gray-700 rounded-r secondaryHoverPrimary"
                >
                    Next
                    <svg
                        aria-hidden="true"
                        className="w-5 h-5 ml-2"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        ></path>
                    </svg>
                </button>
            </div>
        </div>
    )
}

export default Paginator
