import React, { useEffect } from "react"
import { GetStatistics } from "@features/users/account/api/models/responses/GetStatistics"
import { atom, useAtom } from "jotai"
import toast from "react-hot-toast"
import PasteViews from "./statistics/PasteViews"
import PasteRevenue from "./statistics/revenue/PasteRevenue"
import WithdrawalIsland from "./statistics/WithdrawalIsland"
import { getToken } from "@features/users/account/Account.atom"
import { getStatistics } from "@features/users/account/api/Statistics"
import WithdrawalTypeSelection from "@features/users/withdrawals/components/WithdrawalTypeSelection"
import WithdrawalConfirmDetails from "@features/users/withdrawals/components/WithdrawalConfirmDetails"
import RobuxWithdrawalConfirmDetails from "@features/users/withdrawals/components/robux/RobuxWithdrawalConfirmDetails"
import RobuxWithdrawalOverview from "@features/users/withdrawals/components/robux/RobuxWithdrawalOverview"
import WithdrawalOverview from "@features/users/withdrawals/components/WithdrawalOverview"
import WithdrawalAmountSelector from "@features/users/withdrawals/components/WithdrawalAmountSelector"

const StatisticsAtom = atom<GetStatistics | undefined>(undefined)

/**
 * Graphs that display user statistics
 */
const Statistics = () => {
    const [statistics, setStatistics] = useAtom(StatisticsAtom)

    const [ses] = useAtom(getToken)

    useEffect(() => {
        const loadStatistics = async () => {
            try {
                const stats = await getStatistics(ses)

                setStatistics(stats)
            } catch (err) {
                toast.error(JSON.stringify(err))
            }
        }

        if (!statistics) {
            loadStatistics().finally(() => console.log("Statistics have been loaded!"))
        }
    }, [statistics, ses, setStatistics])

    return (
        <div className="flex flex-row w-full">
            <WithdrawalTypeSelection />
            <WithdrawalConfirmDetails />
            <RobuxWithdrawalConfirmDetails />
            <RobuxWithdrawalOverview />
            <WithdrawalOverview />
            <WithdrawalAmountSelector />

            <div className="lg:flex-row flex-col flex justify-evenly my-6 gap-4 w-full">
                <PasteViews stats={statistics?.report} />

                <WithdrawalIsland />

                <PasteRevenue defaultReport={statistics?.revenue} />
            </div>
        </div>
    )
}

export default Statistics
