import React, { useEffect, useState } from "react"
import { getToken } from "@features/users/account/Account.atom"
import { useAtom } from "jotai"
import { getPerPasteRevenue } from "@features/dashboard/components/statistics/api/PasteRevenue"
import PerPasteRevenue from "@features/dashboard/components/statistics/api/models/PerPasteRevenue"
import toast from "react-hot-toast"
import Spinner from "@components/Spinner"
import PerPasteEntries from "@features/dashboard/components/statistics/revenue/PerPasteEntries"

/**
 * A day of revenue's properties.
 *
 * @param index the index this appears in the per day revenue modal.
 * @param amount the amount of money in this revenue.
 * @param date when this revenue was.
 */
type DayRevenueProps = {
    amount: number
    date: number
    index: number
}

/**
 * A single day revenue.
 * @see PerDayRevenue
 */
const DayRevenue = ({ amount, date, index }: DayRevenueProps) => {
    const background =
        index % 2 === 0 ? "secondaryBackground" : "primaryBackground"
    const [ses] = useAtom(getToken)

    const [perPasteVisible, setPerPasteVisible] = useState(false)
    const [perPasteData, setPerPasteData] = useState<PerPasteRevenue>()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        // load per paste data
        const loadPerPasteData = async () => {
            try {
                const data = await getPerPasteRevenue(ses, date)
                setPerPasteData(data)
            } catch (e) {
                toast.error(
                    "There was an issue loading your per paste revenue."
                )
                setPerPasteVisible(false)
            }
        }

        // only load if they set it to visible & it hasn't been loaded before
        if (perPasteVisible && perPasteData === undefined) {
            setLoading(true)

            loadPerPasteData().finally(() => {
                console.log(
                    `Successfully loaded per paste revenue data for ${date}`
                )

                setLoading(false)
            })
        }
    }, [perPasteVisible])

    return (
        <>
            <tr
                className={`${background} border-b border-stone-300 dark:border-stone-800`}
            >
                <th
                    scope="row"
                    className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                    <button onClick={() => setPerPasteVisible((prev) => !prev)}>
                        {new Date(date).toLocaleDateString()}
                    </button>
                </th>
                <td className="py-4 px-6">
                    ${amount > 0 ? amount.toFixed(3) : amount}
                </td>
                <td className="py-3 px-6">
                    {perPasteVisible ? (
                        <>
                            {loading ? (
                                <div>
                                    <Spinner />
                                </div>
                            ) : (
                                <PerPasteEntries
                                    entries={
                                        perPasteData?.perPasteRevenue ?? {}
                                    }
                                />
                            )}
                        </>
                    ) : (
                        <button
                            className="text-greyText dark:text-dark_greyText hover:underline"
                            onClick={() => setPerPasteVisible(true)}
                        >
                            View
                        </button>
                    )}
                </td>
            </tr>
        </>
    )
}

export default DayRevenue
