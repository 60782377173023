import { searchPastes } from "@features/pastes/api/Pastes"
import { SortBy } from "@features/pastes/api/SortBy"
import toast from "react-hot-toast"
import { GetPasteResponse } from "@features/pastes/api/models/GetPasteResponse"

/**
 * Search by a phrase.
 */
export const searchByPhrase = async (phrase: string, updatePastes: (pastes: GetPasteResponse[]) => void) => {
    try {
        const request = await searchPastes(1, phrase, SortBy.RECENT)
        updatePastes(request.pageContents)
    } catch (e) {
        toast.error(`${e}`)
    }
}