import TimeRangeCard from "@components/TimeRangeCard"
import { useAtom } from "jotai"
import { referralStatistics } from "@features/users/referrals/Referrals.atom"

/**
 * Revenue gained from referrals.
 */
const ReferralRevenue = () => {
    const [stats] = useAtom(referralStatistics)

    const onUpdateRange = async (range: string): Promise<number> => {
        switch (range) {
            case "monthly":
                return stats?.monthlyRevenue ?? 0
            default:
                return stats?.lifetimeRevenue ?? 0
        }
    }

    return (
        <TimeRangeCard
            title={"Referral Revenue"}
            ranges={{
                monthly: "Past 30 days",
                lifetime: "Lifetime"
            }}
            defaultRange="monthly"
            initialAmount={stats?.monthlyRevenue ?? 0}
            onUpdateRange={onUpdateRange}
        />
    )
}
export default ReferralRevenue