import React from "react";

interface PerPasteEntriesProps {
    entries: Record<string, number>
}

const PerPasteEntries = ({ entries }: PerPasteEntriesProps) => {
    const individualEntries = Object.entries(entries)

    if (individualEntries.length === 0) {
        return <span>No entries found.</span>
    }

    return (
        <>
            {individualEntries.map(([pasteTitle, revenue]) => (
                <p>
                    <b>
                        {pasteTitle.length === 0
                            ? "Untitled Paste"
                            : pasteTitle}
                    </b>
                    : ${revenue.toFixed(5)}
                </p>
            ))}
        </>
    )
}

export default PerPasteEntries