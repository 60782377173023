import { useNavigate, useSearchParams } from "react-router-dom"
import React, { useEffect, useState } from "react"
import { finalizePasswordReset, validateResetToken } from "@features/users/account/api/PasswordReset"
import Input from "@components/inputs/Input"
import { ErrorMessages } from "@views/account/Register"
import Button from "@components/inputs/Button"
import toast from "react-hot-toast"

/**
 * Reset password page.
 */
const ResetPassword = () => {
    const [searchParams] = useSearchParams()
    const nav = useNavigate()
    const token = searchParams.get("token")

    const [password, setPassword] = useState<string>("")
    const [confirmPassword, setConfirmPassword] = useState<string>("")
    const [loading, setLoading] = useState(false)

    const passwordOkay = RegExp("^(?=.*?[A-Za-z])(?=.*?[0-9]).{8,}$").test(
        password
    )

    if (token === null) {
        nav("/")
    }

    useEffect(() => {
        // if the token is invalid, send them home.
        validateResetToken(token ?? "").then((resp) => {
            if (!resp) {
                nav("/")
            }
        })
    }, [nav, token])

    const onSubmit = async () => {
        setLoading(true)

        const success = await finalizePasswordReset(token ?? "", password)

        if (success) {
            toast.success("Your passwords has successfully been updated!")
            nav("/")
        } else {
            toast.error("There was an issue updating your password, please try again.")
        }

        setLoading(false)
    }

    return (
        <div className="flex flex-row items-center justify-center w-full">
            <div className="island max-w-xl w-full rounded-lg p-4 m-4 flex flex-col gap-4">
                <h1 className="text-2xl poppins">Password Reset</h1>

                {/* Password */}
                <div>
                    <label
                        htmlFor="password"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                        Password
                    </label>
                    <Input
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Your password"
                        value={password}
                        onChange={(e) => setPassword(e.currentTarget.value)}
                        error={
                            password && password !== "" && !passwordOkay
                                ? ErrorMessages.INVALID_PASSWORD
                                : undefined
                        }
                        errorSizing={"w-full"}
                        required
                    />
                </div>

                {/* Confirm Password */}
                <div>
                    <label
                        htmlFor="confirmPassword"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                        Confirm password
                    </label>
                    <Input
                        type="password"
                        name="confirmPassword"
                        id="confirmPassword"
                        placeholder="Confirm your password"
                        errorSizing={"w-full"}
                        value={confirmPassword}
                        onChange={(e) =>
                            setConfirmPassword(e.currentTarget.value)
                        }
                        required
                        error={
                            confirmPassword !== "" &&
                            confirmPassword !== password
                                ? ErrorMessages.INVALID_CONFIRM
                                : undefined
                        }
                    />
                </div>

                <Button className="mt-2" onClick={onSubmit} loading={loading}>Confirm</Button>
            </div>
        </div>
    )
}

export default ResetPassword
