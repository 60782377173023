import { BASE_URL } from "@util/Util"
import {
    AuthorizedGetUserResponse,
    GetUserResponse,
} from "@features/users/models/GetUserResponse"
import { GetUserPastesResponse } from "@features/users/account/api/models/responses/GetUserPastesResponse"
import { API, header } from "@util/Api"
import Payload from "@api/Payload"
import Payment from "@features/users/account/api/models/Payment"

/**
 * Get a user's balance in USD & the user's payment address.
 *
 * @param token The session token to use
 * @returns The balance, or undefined if request fails
 */
export const getBalance = async (token: string): Promise<Payment> => {
    const accountInfo = await API.get(`/user/bal`, header(token))

    const { payload, message }: Payload<Payment> = accountInfo.data

    if (accountInfo.status === 200 && payload) {
        return payload
    } else {
        return Promise.reject(message)
    }
}

/**
 * Get a user by their username.
 *
 * @param username The user's username.
 * @returns a GetUserResponse
 */
export const getUser = async (username: string): Promise<GetUserResponse> => {
    const request = await API.get(`/user?username=${username}`)
    const { payload, message }: Payload<GetUserResponse> = request.data

    if (request.status === 200 && payload) {
        return payload
    } else {
        return Promise.reject(message)
    }
}

/**
 * Get your own pastes.
 *
 * @param session The session token.
 * @param page The page of pastes.
 * @param searchQuery The search query inputted by the user.
 */
export const getUserPastes = async (
    session: string,
    page: number,
    searchQuery?: string
): Promise<GetUserPastesResponse> => {
    const request = await API.get(
        `/user/pastes?page=${page}${
            searchQuery ? `&searchQuery=${searchQuery}` : ``
        }`,
        header(session)
    )

    const { payload, message }: Payload<GetUserPastesResponse> = request.data

    return request.status === 200 && payload ? payload : Promise.reject(message)
}

/**
 * Get a user's profile picture
 *
 * @param username The user to get the profile picture for. undefined if self.
 * @param session The session token.
 */
export const getProfilePicture = async (
    session: string,
    username?: string
): Promise<string | undefined> => {
    const request = await API.get(
        `${BASE_URL}/user/pfp${username && `?username=${username}`}`
    )

    if (request.status === 200) return await request.data
    else return undefined
}

/**
 * Get the account info from the API.
 * @param token The session token to use to find the information
 */
export const getAccountInfo = async (
    token: string
): Promise<AuthorizedGetUserResponse> => {
    console.log("Requesting account info")

    const accountInfo = await API.get(`${BASE_URL}/user`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })

    let body: Payload<AuthorizedGetUserResponse> = accountInfo.data

    if (accountInfo.status === 200) {
        return body.payload as AuthorizedGetUserResponse
    } else return Promise.reject(body.message)
}
