import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { useAtom } from "jotai"
import { useEffect } from "react"
import { referralStorage } from "@features/users/referrals/Referrals.atom"
import { isLoggedIn } from "@features/users/account/Account.atom"

/**
 * Registers the referral token in the query parameters.
 */
export const useReferralRegister = () => {
    const location = useLocation()
    const nav = useNavigate()
    const [searchParams] = useSearchParams()
    const [, setReferral] = useAtom(referralStorage)
    const [loggedIn] = useAtom(isLoggedIn)

    useEffect(() => {
        // the referral search parameter
        const searchReferral = searchParams.get("r")

        if (!loggedIn && searchReferral !== null && searchReferral !== "") {
            setReferral(searchReferral)
            nav(location.pathname)
        }
    }, [loggedIn, searchParams])
}
