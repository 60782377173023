import {useAtom} from "jotai"
import {useNavigate} from "react-router-dom"
import {usePropUpdateModal} from "@components/property/UpdatePropertyModal"
import VipSettings from "./sections/VipSettings"
import {useState} from "react"
import Button from "@components/inputs/Button"
import toast from "react-hot-toast"
import {account, getToken, isLoggedIn, signOut,} from "@features/users/account/Account.atom"
import {loginModalVisibility} from "@features/users/login/Login.atom"
import {deleteUserAccount} from "@features/users/account/api/UpdateAccount"
import ToggleMfa from "@features/users/account/components/ToggleMfa"
import OptionalFeatures from "@features/dashboard/components/settings/sections/OptionalFeatures"
import MonetizationSettings from "@features/dashboard/components/settings/sections/MonetizationSettings"
import AccountSettings from "@features/dashboard/components/settings/sections/AccountSettings"

/**
 * The account page.
 */
const Settings = () => {
    const [deleteLoading, setDeleteLoading] = useState(false)

    const [acc] = useAtom(account)
    const [loggedIn] = useAtom(isLoggedIn)
    const [ses] = useAtom(getToken)
    const [, logOut] = useAtom(signOut)

    const [propModal, editProperty] = usePropUpdateModal()

    // used if they're not signed in
    const [, setLoginVisibility] = useAtom(loginModalVisibility)
    const nav = useNavigate()

    // if they attempt to go to the account page while not logged in, go home and offer modal
    if (!loggedIn) {
        setLoginVisibility(true)
        nav("/")
    }

    // if the user's attempting to delete their account
    const deleteAccount = () => {
        // delete the account itself
        const confirm = async () => {
            setDeleteLoading(true)

            const req = await deleteUserAccount(ses)

            if (req) {
                logOut()
                window.location.reload()
            } else {
                toast.error("There was an error deleting your account.")
                setDeleteLoading(false)
            }
        }

        toast(
            <div className="flex flex-row items-center justify-center">
                <span>
                    Are you sure you want to delete your account?{" "}
                    <b>This action is irreversible.</b>
                </span>

                <Button
                    loading={deleteLoading}
                    onClick={confirm}
                    buttonStyle="danger"
                    className="self-start mt-2 p-2"
                >
                    Yes
                </Button>
            </div>,
            {
                icon: <i className="fas fa-exclamation-triangle"></i>,
            }
        )
    }

    return (
        <div className="flex space-y-4 flex-col w-full items-left">
            <ToggleMfa/>

            {propModal}

            {/* Account Settings */}
            <AccountSettings editProperty={editProperty}/>

            {/* Monetization Features */}
            <MonetizationSettings editProperty={editProperty}/>

            {/* VIP Settings */}
            <div className="flex lg:flex-row flex-col w-full items-center justify-evenly gap-8">
                {((acc?.permissions ?? 0) & 128) > 0 && <VipSettings/>}

                {/* Optional Features */}
                <OptionalFeatures/>
            </div>

            {/* Dangerous Settings */}
            <div className="flex flex-row gap-4">
                <Button buttonStyle={"danger"} onClick={deleteAccount}>
                    Delete Account
                </Button>
            </div>
        </div>
    )
}

export default Settings
