import { BASE_URL } from "@util/Util"
import Payload from "@api/Payload"
import PasteKey from "@features/keys/api/models/PasteKey"
import { Paste } from "@features/pastes/api/models/Paste"
import BeginKeyProcess from "@features/keys/api/models/responses/BeginKeyProcess"
import { API, body, contentHeaders } from "@util/Api"

/**
 * Begin the process of creating a paste key.
 *
 * @param pasteID The paste to initialize creating the key for.
 */
export const startProcess = async (
    pasteID: string
): Promise<BeginKeyProcess> => {
    const request = await API.put(
        `/paste/key`,
        body({ pasteID }),
        contentHeaders()
    )

    const { payload, message }: Payload<BeginKeyProcess> = request.data

    if (request.status === 200 && payload) {
        return payload
    } else {
        return Promise.reject(message)
    }
}

/**
 * Finalize the process with a token, generating a token.
 *
 * @param token The token to unlock the paste.
 * @param turnstile The turnstile token to verify the request.
 */
export const finalizeProcess = async (
    token: string,
    turnstile: string
): Promise<string> => {
    const request = await API.post(
        `/paste/key`,
        body({ token, turnstile }),
        contentHeaders()
    )

    const { payload, message }: Payload<{ token: string; details: PasteKey }> =
        request.data

    if (request.status === 200 && payload) {
        return payload.token
    } else {
        return Promise.reject(message)
    }
}

/**
 * Get details about the paste.
 * @param key
 */
export const getKeyDetails = async (
    key: string
): Promise<{ paste: Paste; expires: number }> => {
    const request = await API.get(`${BASE_URL}/paste/key?token=${key}`)

    const { payload, message }: Payload<{ paste: Paste; expires: number }> =
        request.data

    if (request.status === 200 && payload) {
        return payload
    } else {
        return Promise.reject(message)
    }
}
