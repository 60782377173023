import { atom, useAtom } from "jotai"
import { FormEvent, useState } from "react"
import toast from "react-hot-toast"
import { createFeedback } from "../api/FeedbackManagement"
import Modal from "../../../components/Modal"
import { getToken } from "@features/users/account/Account.atom"
import TextArea from "@components/inputs/TextArea"
import Button from "@components/inputs/Button"

/**
 * Visibility of Feedback modla
 */
export const feedbackModalVisibility = atom(false)

/**
 * The feedback modal.
 */
const FeedbackModal = () => {
    const [visible, setVisible] = useAtom(feedbackModalVisibility)
    const [loading, setLoading] = useState(false)
    const [sesToken] = useAtom(getToken)

    // when the form is submitted
    const onSubmit = async (ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault()

        setLoading(true)

        // capture target now, otherwise doesn't work
        const target = ev.currentTarget

        // turn the form data into an object
        const data = Object.fromEntries(new FormData(target))

        const content = data.content
        const category = data.category

        // this is mostly for category, if unset this error appears.
        if (!content || !category) {
            toast.error("Invalid category or content!")
            setLoading(false)
            return
        }

        // the success boolean and string message from creating.
        try {
            const feedbackID = await createFeedback(
                sesToken,
                data.content.toString(),
                data.category.toString()
            )

            setVisible(false)
            target?.reset()
            toast.success(`Created feedback! Your feedback ID: ${feedbackID}`)
        } catch (e) {
            toast.error(`${e}`)
        }

        setLoading(false)
    }

    return (
        <Modal visible={visible} setVisible={setVisible} title="Feedback">
            <form className="space-y-6" onSubmit={onSubmit}>
                <div>
                    <label
                        htmlFor="feedbackContent"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                        Content
                    </label>
                    <TextArea
                        name="content"
                        className="h-48"
                        id="feedbackContent"
                        placeholder="Your feedback"
                        required
                    />
                </div>
                <div className="flex flex-row justify-evenly">
                    <div className="flex items-center">
                        <label
                            htmlFor="bug"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                            Bug
                            <input
                                type="radio"
                                id="bug"
                                value="BUG"
                                name="category"
                                className="ml-2"
                            />
                        </label>
                    </div>
                    <div className="flex items-center">
                        <label
                            htmlFor="suggestion"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                            Suggestion
                            <input
                                type="radio"
                                id="suggestion"
                                value="SUGGESTION"
                                name="category"
                                className="ml-2"
                            />
                        </label>
                    </div>
                    <div className="flex items-center flex-col">
                        <label
                            htmlFor="etc"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                            ETC
                            <input
                                type="radio"
                                id="etc"
                                value="ETC"
                                name="category"
                                className="ml-2"
                            />
                        </label>
                    </div>
                </div>
                <div className="flex flex-row gap-2">
                    <Button loading={loading} type="submit">
                        Submit Feedback
                    </Button>
                    <Button
                        buttonStyle="secondary"
                        onClick={() => setVisible(false)}
                        type="button"
                    >
                        Close
                    </Button>
                </div>
            </form>
        </Modal>
    )
}

export default FeedbackModal
