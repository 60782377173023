import { useState } from "react"
import { Visibility as Vis } from "../../api/Visibility"
import React, { useEffect } from "react"
import { capitalize } from "@util/Util"
import { useAtom } from "jotai"
import { isLoggedIn } from "@features/users/account/Account.atom"

/**
 * An option for visibility.
 */
const VisibilityOption: React.FC<{
    setVis: (newVis: number) => void // sets the visibility to the main component
    visibility: Vis // the visibility enum
}> = ({ visibility, setVis }) => {
    return (
        <li>
            <button onClick={() => setVis(visibility)} type="button">
                {capitalize(Vis[visibility])}
            </button>
        </li>
    )
}

/**
 * Manages visibility for a paste.
 */
const Visibility: React.FC<{ defaultValue?: Vis }> = ({ defaultValue }) => {
    const [visible, setVisible] = useState(false)
    const [vis, setVis] = useState(defaultValue ?? Vis.PUBLIC)
    const [loggedIn] = useAtom(isLoggedIn)

    const dropDownRef = React.useRef<HTMLOListElement>(null)

    // change to the selected visibility and close the dropdown
    const changeVis = (newVis: number) => {
        setVisible(false)
        setVis(newVis)
    }

    // closes the dropdown when the user clicks off
    useEffect(() => {
        function handleClickOutside(event: MouseEvent): void {
            if (
                dropDownRef.current &&
                !dropDownRef.current.contains(event.target as Node)
            ) {
                setVisible(false)
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside)

        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [dropDownRef])

    return (
        <div className="flex justify-center w-full">
            <div className="w-full">
                <div className="relative">
                    {/* Input that stores the visibility state */}
                    <input
                        value={capitalize(Vis[vis])}
                        name="visibility"
                        className={`textInput cursor-pointer caret-transparent`}
                        onClick={() => setVisible((vis) => !vis)}
                    />

                    {/* Different visibility options */}
                    <ul
                        className="dropdown"
                        hidden={!visible}
                        ref={dropDownRef}
                    >
                        <VisibilityOption
                            visibility={Vis.PUBLIC}
                            setVis={changeVis}
                        />

                        <VisibilityOption
                            visibility={Vis.UNLISTED}
                            setVis={changeVis}
                        />

                        {/* Only show private if the user's logged in */}
                        {loggedIn && (
                            <VisibilityOption
                                visibility={Vis.PRIVATE}
                                setVis={changeVis}
                            />
                        )}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Visibility
