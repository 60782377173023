import React, { useEffect, useState } from "react"
import { popularPastes } from "@features/pastes/api/Pastes"
import toast from "react-hot-toast"
import { Paste as PasteApi } from "@features/pastes/api/models/Paste"
import { ThumbnailPaste } from "@components/ThumbnailPaste"

/**
 * Popular paste loading skeleton
 */
const PasteThumbnailSkeleton = () => {
    const SkeletonRow = () => {
        // randomly selected color
        const color = Math.floor(Math.random() * 3)

        // 3 rows, one must be 100% to fill the rest (if any remaining)
        const [skeletonWidths, setSkeletonWidths] = useState<number[]>([
            Math.floor(Math.random() * 100) + 10,
            Math.floor(Math.random() * 100) + 10,
            100,
        ])

        useEffect(() => {
            // shuffle widths
            setSkeletonWidths((array) => {
                const newArray = array

                for (let i = newArray.length - 1; i > 0; i--) {
                    let j = Math.floor(Math.random() * (i + 1))
                    let temp = newArray[i]
                    newArray[i] = newArray[j]
                    newArray[j] = temp
                }

                return newArray
            })
        }, [])

        // the random classes for the skeleton
        const getSkeletonClasses = (index: number) => {
            return index === color
                ? `h-2.5 rounded-full bg-gray-200 dark:bg-gray-700`
                : `h-2.5 rounded-full bg-gray-300 dark:bg-gray-600`
        }

        return (
            <div className="flex items-center w-full space-x-1">
                {Array(3) // 4 per row
                    .fill(0)
                    .map((_, index) => (
                        // Skeleton itself
                        <div
                            style={{ width: `${skeletonWidths[index]}%` }}
                            className={getSkeletonClasses(index)}
                        ></div>
                    ))}
            </div>
        )
    }

    return (
        <div className={"thumb animate-pulse space-y-2"}>
            {/* Video loading icon */}
            <div className="aspect-video w-full h-full cursor-pointer flex items-center justify-center bg-gray-300 rounded-xl dark:bg-gray-700">
                <svg
                    className="w-12 h-12 text-gray-200"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 640 512"
                >
                    <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                </svg>
            </div>

            <SkeletonRow />
            <SkeletonRow />
        </div>
    )
}

/**
 * Popular pastes on the homepage
 */
const PopularPastes = () => {
    // the loaded pastes
    const [pastes, setPastes] = useState<PasteApi[]>()

    // shows an error message if there was an issue loading the pastes.
    const [error, setError] = useState(false)

    // loads the popular pastes on page load.
    useEffect(() => {
        const loadPastes = async () => {
            try {
                const request = await popularPastes()
                setPastes(request)
            } catch (e) {
                setError(true)
                toast.error(`${e}`)
            }
        }

        loadPastes().then(() =>
            console.log("Completed loading pastes for popular pastes!")
        )
    }, [])

    // if there was an issue loading the pastes
    if (error) {
        return (
            <p className="text-center lg:w-1/4 w-full">
                <i className="fa fa-error" />
            </p>
        )
    }

    return (
        <section id="popular" className="island rounded-lg p-4 mt-20">
            <h1 className="text-3xl poppins font-semibold text-center mt-5 mb-10">
                Popular Pastes
            </h1>

            <div className="flex flex-col justify-center items-center gap-8 w-full">
                {window.screen.width >= 768 ? (
                    Array(3)
                        .fill(0)
                        .map((num, index) => {
                            return (
                                <div
                                    key={index}
                                    className="flex flex-row w-full gap-8"
                                >
                                    {pastes
                                        ?.slice(index * 4, index * 4 + 4)
                                        ?.map((paste) => (
                                            <ThumbnailPaste
                                                key={paste.pasteID}
                                                paste={paste}
                                            />
                                        )) ?? (
                                        <>
                                            <PasteThumbnailSkeleton />
                                            <PasteThumbnailSkeleton />
                                            <PasteThumbnailSkeleton />
                                            <PasteThumbnailSkeleton />
                                        </>
                                    )}
                                </div>
                            )
                        })
                ) : (
                    <div className="flex flex-col w-full gap-8">
                        {pastes?.map((paste) => (
                            <ThumbnailPaste key={paste.pasteID} paste={paste} />
                        )) ?? <PasteThumbnailSkeleton />}
                    </div>
                )}
            </div>
        </section>
    )
}

export default PopularPastes
