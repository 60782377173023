import React from "react"
import { PropertyProps } from "@components/property/PropertyProps"

interface ButtonPropertyProps extends PropertyProps {
    buttonAction: () => void
    breakAll?: boolean
}

/**
 * A property on the Account page.
 *
 * Shows title of property, status of property, then a button used to modify that property.
 */
const Property: React.FC<ButtonPropertyProps> = ({
    title,
    status,
    buttonAction,
    breakAll,
}) => {
    return (
        <div className="acc-set-column bg-primaryBackground dark:bg-dark_primaryBackground p-4 rounded-lg">
            <div className="flex justify-between gap-2">
                <div className="flex flex-col">
                    <span className="text-sm text-gray-500 font-medium">
                        {title}
                    </span>
                    <span
                        className={`font-semibold ${
                            breakAll ? "break-all" : "break-words"
                        } max-w-xs text-lg text-black dark:text-white`}
                    >
                        {status}
                    </span>
                </div>
                <button
                    type="button"
                    className="acc-set-button self-start"
                    onClick={buttonAction}
                >
                    <i className="fa-solid fa-pen" />
                </button>
            </div>
        </div>
    )
}

export default Property
