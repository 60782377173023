import Spinner from "../Spinner"
import React from "react"

/**
 * Props for the button wrapper.
 */
type ButtonProps = {
    loading?: boolean // when true, a loading icon appears and the button is disabled
    sizing?: "large" | "standard" // the sizing of the button
    buttonStyle?: "primary" | "secondary" | "danger" | "success" // changes which color the button will have
} & React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
>

/**
 * A button wrapper with custom styles.
 */
const Button: React.FC<ButtonProps> = (props) => {
    const {
        sizing,
        type,
        disabled,
        loading,
        className,
        buttonStyle,
        children,
    } = props

    return (
        <button
            {...props}
            type={type ?? "button"}
            disabled={disabled || loading}
            className={`${className} ${buttonStyle ?? "primary"}-button ${
                sizing ?? "standard"
            }ButtonSizing`}
        >
            {children} {loading && <Spinner />}
        </button>
    )
}

export default Button
