import { atom } from "jotai"
import { MfaLoginResponse } from "@features/users/login/api/responses/MfaLoginResponse"

/**
 * if the login modal is visible.
 */
export const loginModalVisibility = atom(false)

/**
 * If the register modal is visible.
 */
export const registerModalVisibility = atom(false)

/**
 * If the forgot password modal is visible.
 */
export const forgotPasswordVisibility = atom(false)

/**
 * Used by TwoFactor.tsx to continue the two-factor logging in within the LoginModal.
 */
export const twoFactorContinue = atom(undefined as MfaLoginResponse | undefined)

/**
 * If the screen prompting for two factor authorization is visible.
 */
export const twoFactorVisibility = atom(false)
